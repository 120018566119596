import React from 'react';
import moment from 'moment';
import _ from 'lodash/fp/lang';
import { connect } from 'react-redux';
import { Tournament } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Grid,
  Paper,
  colors,
  ButtonBase,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  AccessTime as AccessTimeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  InfoOutlined as InfoOutlinedIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from '@material-ui/icons';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { CalendarDay } from '_components/elements';

/* */
const months = i18n
  .translations[i18n.locale]
  .date
  .month_names
  .slice(1)
  .map(m => m.toUpperCase());
  // .map((y, i) => ({ label: y, value: i + 1 }));

/* */
const styles = theme => ({
  trnName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  loaderContainer: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 4}px 0`,
  },

  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  select: { minWidth: 120 },
  oHidden: { overflow: 'hidden' },
  itemContainer: { display: 'block' },
  registrationWarning: { color: '#FF7E07' },
  registrationClose: { color: colors.red[500] },
  registrationOpen: { color: colors.green[500] },
  lifecycle: { color: theme.palette.text.primary },
});

/* */
const getColorStatus = (status) => {
  switch (status) {
    case 1:
    case 2:
      return 'registrationOpen';
    case 3:
      return 'registrationWarning';
    default:
      return 'registrationClose';
  }
};

/* */
class TournamentCalendar extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    // Data
    loading: true,
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  componentDidUpdate(prevProps) {
    const { calendar } = this.props;
    if (!_.isEqual(calendar, prevProps.calendar)) {
      this.load();
    }
  }

  /* */
  load() {
    const { golf, calendar: { year, month } } = this.props;

    const to = moment().year(year).month(month).endOf('month'); // Months are 0 indexed
    const from = moment().year(year).month(month).startOf('month');

    this.setState({
      error: null,
      loading: true,
    });

    const params = {
      golfId: golf.id,
      to: to.format('YYYY-MM-DD'),
      from: from.format('YYYY-MM-DD'),
    };

    Tournament.api.getCalendar(apolloClient, params)
      .then(({ tournaments }) => this.props.setTournaments(tournaments))
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  onChange = val => () => {
    const { calendar: { year, month } } = this.props;
    const calendar = {
      month: month + val,
      year,
    };
    if (calendar.month >= 12) {
      calendar.month = 0;
      calendar.year += 1;
    } else if (calendar.month < 0) {
      calendar.month = 11;
      calendar.year -= 1;
    }
    this.props.setCalendar(calendar);
  }

  /* */
  goToView = tournament => () => {
    let screen = `/tournament/${tournament.id}`;

    if (tournament.lifecycle === 'results') screen = `/tournament/${tournament.id}/results`;
    if (tournament.lifecycle === 'teetime') screen = `/tournament/${tournament.id}/startlist`;

    return screen ? this.props.history.push(screen) : null;
  }

  /* */
  renderList() {
    const { loading } = this.state;
    const { classes, tournaments = [] } = this.props;

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress
            size={32}
            thickness={4}
            color="secondary" />
        </div>
      );
    }

    if (tournaments.length === 0) {
      return (
        <div className={classes.loaderContainer}>
          <Typography variant='h5'>{'Aucune compétition.'}</Typography>
        </div>
      );
    }

    return (
      <Grid
        container
        spacing={24}>
        {tournaments.map(this.renderTournament)}
      </Grid>
    );
  }

  /* */
  renderTournament = (item) => {
    const {
      id,
      name,
      lifecycle,
      startDate,
      gameFormula,
      informations,
    } = item;
    const { classes } = this.props;

    let icon;
    let status;
    let statusText;
    let classCSS = classes.lifecycle;

    if (lifecycle === 'registration') {
      icon = <LockIcon />;
      ({ status, statusText } = Tournament.functions.getRegistrationStatus(item));
      classCSS = classes[getColorStatus(status)];
      icon = status > 0
        ? <LockOpenIcon className={classCSS} />
        : <LockIcon className={classCSS} />;
    } else if (lifecycle === 'teetime') {
      icon = <AccessTimeIcon className={classCSS} />;
      statusText = 'Départs publiés';
    } else if (lifecycle === 'results') {
      icon = <FormatListNumberedIcon className={classCSS} />;
      statusText = 'Résultats publiés';
    }

    return (
      <Grid
        item
        key={id}
        md={6} sm={10} xs={12}>
        <ButtonBase
          disableRipple
          component="div"
          onClick={this.goToView(item)}
          className={classes.itemContainer}>
          <Paper
            className={classes.item}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start">
              <CalendarDay datetime={moment(startDate, 'YYYY-MM-DD')} />
              <Box
                ml={2}
                flex={1}
                display="flex"
                flexDirection="column"
                className={classes.oHidden}>
                <Typography
                  color="secondary"
                  variant="subtitle1"
                  className={classes.trnName}>
                  {name}
                </Typography>
                <Typography gutterBottom>{gameFormula}</Typography>
                {(icon && statusText) && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center">
                    {icon}&nbsp;
                    <Typography className={classCSS}>{statusText}</Typography>
                  </Box>
                )}
                {informations && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    mt={1}>
                    <InfoOutlinedIcon />&nbsp;
                    <Typography
                      noWrap>
                      {informations.replace('<br/>', ' ')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </ButtonBase>
      </Grid>
    );
  }

  /* */
  render() {
    const { error } = this.state;
    const { calendar } = this.props;

    return (
      <Screen
        error={error}
        title="Compétitions - Calendrier">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-evenly">
          <IconButton onClick={this.onChange(-1)} color="primary">
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6">
            {months[calendar.month]} {calendar.year}
          </Typography>
          <IconButton onClick={this.onChange(1)} color="primary">
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <br />
        <br />
        {this.renderList()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, tournament }) => ({
  golf: app.golf,
  calendar: tournament.calendar,
  tournaments: tournament.list,
});

const StyledComponent = withStyles(styles)(TournamentCalendar);

export default connect(
  mapStateToProps,
  Tournament.actions,
)(StyledComponent);
