import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  helpers,
  Event,
  Paybox,
  CreditCard,
} from '@aps-management/primapp-common';
import { Typography } from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = {};

/* */
class EventPayment extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      golf,
      enter,
      account,
      match: { params },
    } = props;

    this.id = params.id;
    this.redirect = false;

    if (!enter.status.includes('cart')) {
      this.redirect = true;
      return;
    }

    // init data
    const {
      total,
      events,
      // entrants,
    } = enter;

    // check all options are selected
    const checkEnterEvents = events.length > 0
      ? enter.entrants
        .every(en => events
          .every(req => en.events
            .find(ev => ev && (ev.id === req.id))))
      : false;

    // init data
    this.data = {
      amount: total.events,
      // amount: entrants.reduce((tot, entrant) => (tot
      //   + ((entrant.tournament
      //     && (entrant.tournament.greenFee + entrant.tournament.entryFee)) || 0)
      //   + ((entrant.events
      //     && entrant.events.reduce((acc, e) => (acc + e.amount), 0)) || 0)
      // ), 0),
      reference: ((events.length && `EVENTS${events.reduce((acc, e) => (`${acc}-${e.id}`), '')}`) || ''),
    };

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
      },
    };
    this.currency = golf.currency;

    this.state = {
      error: null,
      status: null,
      imprint: null,
      loading: true,
      waiting: false,
      expanded: null,
      checkEnterEvents,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
          expanded: 'other',
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
          newState.expanded = 'imprint';
        }

        this.setState(newState);
      });
  }

  /* */
  setEntry = () => {
    const { enter, golf } = this.props;
    const { transaction } = this.data;

    return Event.functions.asyncSetEntry(apolloClient, { enter, golf, transaction })
      .then((result) => {
        // TODO: update contacts with new tid
        if (result && result.players) {
        //   players.forEach(({ tid }, index) => {
        //     if (entrants[index].reference !== tid) {
        //     }
        //   });
        }
        this.props.enter.transaction = transaction;
        this.goToVoucher();
      });
  }

  /* */
  goToVoucher = () => {
    this.props.enter.status.push('payment');
    this.props.history.push(`/event/${this.id}/voucher`);
  }

  /* */
  payWithCardImprint = () => {
    const { imprint } = this.state;

    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    return Paybox.api.captureSubscriber(apolloClient, {
      ...this.data,
      subscriber,
    })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
        }
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        this.data.transaction = {
          mode: 0, // par CB
          card: imprint.number,
          callNo: res.NUMAPPEL,
          amount: this.data.amount,
          creditCardId: imprint.id,
          transactionNo: res.NUMTRANS,
          ...helpers.object.filterByKeys(imprint, ['type', 'brand', 'country']),
        };

        return this.setEntry();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
      });
  }

  /* */
  capture(creditCard) {
    const { account } = this.props;
    const { id3D = null } = creditCard;
    const { amount, reference } = this.data;

    if (creditCard.saveImprint) {
      const uuid = Paybox.functions.createUuidFrom(account.id);

      return Paybox.api.subscribeAndCapture(apolloClient, {
        uuid,
        id3D,
        amount,
        reference: account.id,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { handled: true });
        })
        .then(res => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'country', 'brand', 'type']),
        })
          .then(() => res))
        .catch((err) => {
          if (!err.handled) {
            throw Object.assign(err, {
              handled: true,
              message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
            });
          }
          throw err;
        });
    }

    return Paybox.api.capture(apolloClient, {
      id3D,
      amount,
      reference,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    });
  }

  /* */
  payWithNewCard = (values, { setSubmitting }) => {
    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    return this.capture(creditCard)
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
        }
        throw err;
      })
      .then((res) => {
        this.data.transaction = {
          mode: 0, // par CB
          callNo: res.NUMAPPEL,
          amount: this.data.amount,
          transactionNo: res.NUMTRANS,
          card: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        };

        return this.setEntry();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
        if (setSubmitting) setSubmitting(false);
      });
  };

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to={`/event/${this.id}`} />;
    }

    const { golf } = this.props;
    const {
      error,
      status,
      imprint,
      loading,
      waiting,
      checkEnterEvents,
    } = this.state;
    const { amount } = this.data;

    return (
      <Screen
        error={error}
        loading={loading}
        title={i18n.getFromOpts('event_pay.title', golf.options)}>
        {checkEnterEvents ? (
          <Payment
            amount={amount}
            imprint={imprint}
            currency={this.currency}
            session3DS={this.session3DS}
            payWithNewCard={this.payWithNewCard}
            payWithCardImprint={this.payWithCardImprint} />
        ) : (
          <Typography
            color="error"
            align="center"
            variant="h6">
            {'Votre inscription est incomplète !'}<br />
            {'Veuillez choisir une option pour tout le monde.'}
          </Typography>
        )}
        <DialogPaymentIP open={waiting} status={status} />
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, eventEnter }) => ({
  golf: app.golf,
  enter: eventEnter,
  account: app.account,
});

const StyledComponent = withStyles(styles)(EventPayment);

export default connect(
  mapStateToProps,
  Event.actions,
)(StyledComponent);
