import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Directory } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  Fab,
  Grid,
  List,
  Paper,
  Button,
  Dialog,
  ListItem,
  IconButton,
  Typography,
  ListItemText,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { AddContactForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  lastname: validators.required,
  firstname: validators.required,
});

/* */
const styles = theme => ({
  item: {
    '&, &:hover, &:active': {
      '& $text': {
        color: theme.palette.secondary.dark,
      },
    },
  },
  text: {},
  deleteBtn: {
    float: 'right',
    top: theme.spacing.unit * -1,
  },
  confirmBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

/* */
class ManageContacts extends React.Component {
  /* */
  state = {
    // Data
    open: false,
    contact: null,
    waiting: false,
    // Screen
    error: null,
    success: null,
    loading: true,
  };

  /* */
  componentDidMount() {
    if (this.props.contacts === null) {
      Directory.api.getContacts(apolloClient, {}, 'no-cache')
        .then(({ contacts }) => { this.props.setContactsList(contacts); })
        .catch(({ message: error }) => this.setState({ error }))
        .finally(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  }

  /* */
  renderContactList() {
    const { contacts } = this.props;

    if (contacts && contacts.length > 0) {
      return (
        <Paper>
          <List>
            {contacts.map(this.renderContact)}
          </List>
        </Paper>
      );
    }

    return (
      <Typography
        component="p"
        variant="subtitle1">
        {'Aucun contact dans votre répertoire.'}
      </Typography>
    );
  }

  /* */
  renderContact = (c, i) => {
    const { classes } = this.props;
    const { contact } = this.state;

    const customProps = {};
    const selected = contact && c.uid === contact.uid;

    if (selected) customProps.className = classes.item;

    return (
      <ListItem
        button
        disableRipple
        key={i}
        selected={selected}
        onClick={this.handleSelect(c)}
        {...customProps}>
        <ListItemText
          classes={{ primary: classes.text }}
          primary={`${c.firstname} ${c.lastname}`} />
      </ListItem>
    );
  };

  /* */
  doEditRequest = (values) => {
    const isNew = !values.uid;

    this.setState({
      error: null,
      success: null,
    });

    Directory.api.setContact(apolloClient, { ...values })
      .then(({ contact }) => {
        if (isNew) {
          this.props.addContact(contact);
        } else {
          this.props.updateContact(contact);
        }
        this.setState({
          contact: null,
          success: isNew
            ? 'Un nouveau contact a été ajouté.'
            : 'Le contact a été modifié avec succès.',
        });
      })
      .catch((e) => { this.setState({ error: e.message }); });
  };

  doDeleteRequest = () => {
    const { contact } = this.state;

    this.setState({
      error: null,
      waiting: true,
      success: null,
    });

    Directory.api.deleteContact(apolloClient, { uid: contact.uid })
      .then(() => {
        this.setState({
          contact: null,
          success: 'Le contact a été supprimé avec succès.',
        });
        this.props.deleteContact(contact);
      })
      .catch((e) => { this.setState({ error: e.message }); })
      .finally(() => this.setState({
        open: false,
        waiting: false,
      }));
  }

  /* */
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  /* */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* */
  renderForm() {
    const { classes } = this.props;
    const { contact } = this.state;

    if (contact === null) return null;

    const isNew = !contact.uid;

    return (
      <React.Fragment>
        {!isNew && (
          <IconButton
            aria-label="Delete"
            className={classes.deleteBtn}
            onClick={this.handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        )}
        <Typography
          gutterBottom
          variant="h6"
          component='h2'>
          {contact.uid
            ? 'Editer le contact'
            : 'Ajouter un contact'}
        </Typography>
        <Formik
          initialValues={contact}
          onSubmit={this.doEditRequest}
          enableReinitialize={true}
          component={AddContactForm}
          validationSchema={validationSchema} />
      </React.Fragment>
    );
  }

  /* */
  handleAdd = () => {
    this.setState({
      contact: {
        uid: null,
        email: '',
        phone: '',
        lastname: '',
        firstname: '',
      },
    });
  }

  /* */
  handleSelect = c => () => {
    this.setState({ contact: c });
  }

  /* */
  renderDialog() {
    const { classes } = this.props;
    const { open, waiting } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}>
        <DialogContent>
          <DialogContentText>
            {'Êtes-vous sûr de vouloir supprimer ce contact ?'}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            size="large"
            color="primary"
            disabled={waiting}
            variant="contained"
            onClick={this.doDeleteRequest}
            className={classes.confirmBtn}>
            {waiting
              ? <CircularProgress color="inherit" size={24} />
              : 'Confirmer'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  render() {
    const { loading, success, error } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title="Gérer mes contacts"
        onBackPress={() => this.props.history.goBack()}>
        <Fab
          color="secondary"
          aria-label="Ajouter un contact"
          onClick={this.handleAdd}>
          <AddIcon />
        </Fab>
        <br />
        <br />
        <Grid
          container
          spacing={40}>
          <Grid item md={6} xs={12}>
            {this.renderContactList()}
          </Grid>
          <Grid item md={6} xs={12}>
            {this.renderForm()}
          </Grid>
        </Grid>
        {this.renderDialog()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ directory: { contacts } }) => ({ contacts });

const StyledComponent = withStyles(styles)(ManageContacts);

export default connect(mapStateToProps, Directory.actions)(StyledComponent);
