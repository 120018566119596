import React from 'react';
import moment from 'moment';
import _ from 'lodash/fp/lang';
import { connect } from 'react-redux';
import { App, Event } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Grid,
  Paper,
  colors,
  ButtonBase,
  IconButton,
  Typography,
  NativeSelect,
  CircularProgress,
} from '@material-ui/core';
import {
  Lock as LockIcon,
  Check as CheckIcon,
  LockOpen as LockOpenIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { CalendarDay } from '_components/elements';

/* */
const months = i18n
  .translations[i18n.locale]
  .date
  .month_names
  .slice(1)
  .map(m => m.toUpperCase());
  // .map((y, i) => ({ label: y, value: i + 1 }));

/* */
const styles = theme => ({
  eventName: {
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // textOverflow: 'ellipsis',
  },
  loaderContainer: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 4}px 0`,
  },
  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  filter: {
    marginRight: 24,
    marginBottom: 24,
  },
  oHidden: { overflow: 'hidden' },
  itemContainer: { display: 'block' },
  lifecycleClosed: { color: colors.red[500] },
  lifecycleOpened: { color: colors.green[500] },
  lifecycle: { color: theme.palette.text.primary },
});

/* */
class Calendar extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    // Data
    loading: true,
    filterValues: {},
  };

  filters = [];

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  componentDidUpdate(prevProps) {
    const { calendar } = this.props;
    if (!_.isEqual(calendar, prevProps.calendar)) {
      this.load();
    }
  }

  /* */
  load() {
    const { account, golf, calendar: { year, month } } = this.props;

    const to = moment().year(year).month(month).endOf('month'); // Months are 0 indexed
    const from = moment().year(year).month(month).startOf('month');

    this.setState({
      error: null,
      loading: true,
      filterValues: {},
    });

    const match = App.functions.match(account, golf) || { reference: 'UNKNOWN' };

    const params = {
      golfId: golf.id,
      reference: match.reference,
      to: to.format('YYYY-MM-DD'),
      from: from.format('YYYY-MM-DD'),
    };

    Event.api.getCalendar(apolloClient, params)
      .then(({ events }) => {
        this.props.setEvents(events);

        // search name filters
        this.filters = Event.functions.getFilters(events);
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  onChange = val => () => {
    const { calendar: { year, month } } = this.props;
    const calendar = {
      month: month + val,
      year,
    };
    if (calendar.month >= 12) {
      calendar.month = 0;
      calendar.year += 1;
    } else if (calendar.month < 0) {
      calendar.month = 11;
      calendar.year -= 1;
    }
    this.props.setCalendar(calendar);
  }

  /* */
  goToEnter = event => () => this.props.history.push(`/event/${event.id}`)

  /* */
  renderList() {
    const { classes, events = [], golf } = this.props;
    const { loading, filterValues } = this.state;

    if (loading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress
            size={32}
            thickness={4}
            color="secondary" />
          </div>
      );
    }

    // Ne pas afficher les événements qui nécessitent de s'inscrire à une compétition
    const displayedEvents = events
      .filter(({ tournament }) => !(tournament && tournament.required))
      .filter(({ name }) => Object.keys(filterValues).every(v => name.includes(filterValues[v])));

    if (displayedEvents.length === 0) {
      return (
        <div className={classes.loaderContainer}>
          <Typography variant='h5'>{i18n.getFromOpts('event_calendar.not_found', golf.options)}</Typography>
        </div>
      );
    }

    return (
      <Grid
        container
        spacing={24}>
        {displayedEvents.map(this.renderEvent)}
      </Grid>
    );
  }

  /* */
  renderEvent = (item) => {
    const {
      id,
      name,
      lifecycle,
      startDate,
      limit,
      counter,
    } = item;
    const { classes } = this.props;

    let icon;
    let status;
    let statusText;
    let classCSS = classes.lifecycle;

    if (lifecycle === 'registration') {
      icon = <LockIcon />;
      ({ status, statusText } = Event.functions.getRegistrationStatus(item));
      classCSS = status > 0 ? classes.lifecycleOpened : classes.lifecycleClosed;
      icon = status > 0
        ? <LockOpenIcon className={classCSS} />
        : <LockIcon className={classCSS} />;
    } else {
      statusText = 'Terminé';
      classCSS = classes.lifecycleOpened;
      icon = <CheckIcon className={classCSS} />;
    }

    return (
      <Grid
        item
        key={id}
        md={6} sm={10} xs={12}>
        <ButtonBase
          disableRipple
          component="div"
          onClick={this.goToEnter(item)}
          className={classes.itemContainer}>
          <Paper
            className={classes.item}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start">
              <CalendarDay datetime={moment(startDate, 'YYYY-MM-DD')} />
              <Box
                ml={2}
                flex={1}
                display="flex"
                flexDirection="column"
                className={classes.oHidden}>
                <Typography
                  color="secondary"
                  variant="subtitle1"
                  className={classes.eventName}>
                  {Event.functions.sanitize(name)}
                </Typography>
                {lifecycle === 'registration'
                  && limit
                  && (limit - counter > 0)
                  && (
                    <Typography gutterBottom variant="caption">
                      {`${limit - counter} places restantes`}
                    </Typography>
                  )}
                {(icon && statusText) && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center">
                    {icon}&nbsp;
                    <Typography className={classCSS}>{statusText}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </ButtonBase>
      </Grid>
    );
  }

  /* */
  handleFilter = key => (event) => {
    const filterValues = { ...this.state.filterValues };
    filterValues[key] = event.target.value;
    this.setState({ filterValues });
  };

  /* */
  renderFilters() {
    const { filters } = this;
    const { classes } = this.props;
    const { loading } = this.state;

    if (!filters.length || loading) return null;

    return (
      <React.Fragment>
        {filters.map((filter, key) => (
          <NativeSelect
            key={key}
            onChange={this.handleFilter(key)}
            className={classes.filter}
            inputProps={{
              id: `filter_${key}`,
              name: `filter_${key}`,
            }}>
            <option value="">{'Tous'}</option>
            {filter.sort().map((f, i) => <option key={i} value={f}>{f}</option>)}
          </NativeSelect>
        ))}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const { error } = this.state;
    const { calendar, golf } = this.props;

    return (
      <Screen
        error={error}
        title={i18n.getFromOpts('event.title', golf.options)}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-evenly">
          <IconButton onClick={this.onChange(-1)} color="primary">
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6">
            {months[calendar.month]} {calendar.year}
          </Typography>
          <IconButton onClick={this.onChange(1)} color="primary">
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <br />
        <br />
        {this.renderFilters()}
        {this.renderList()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, event }) => ({
  golf: app.golf,
  events: event.list,
  account: app.account,
  calendar: event.calendar,
});

const StyledComponent = withStyles(styles)(Calendar);

export default connect(
  mapStateToProps,
  Event.actions,
)(StyledComponent);
