/*
!!! DEPRECATED !!!
Utilisé pour UGolf SEPA -> CB-V
*/
import React from 'react';
import moment from 'moment';
import * as qs from 'query-string';
// import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  App,
  Paybox,
  Account,
  helpers,
  Membership,
  CreditCard,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Done as DoneIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import GetCreditCard from '_components/GetCreditCard';
import { Alert, DialogPaymentIP, CreditCardPreview } from '_components/elements';

/* */

class ConfirmMembershipSchedule extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.authorizationAmount = 1000;

    this.state = {
      // ux
      error: null,
      success: null,
      loading: true,
      imprint: null,
      expanded: false,
      screenLoading: true,
      buttonLoading: false,
      // data
      golf: null,
      account: null,
      creditCard: null,
    };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { token } = qs.parse(this.props.location.search);

    if (token) {
      Account.api.getAccountByToken(apolloClient, { token })
        .then((account) => {
          if (account) {
            const matching = account.matches.find(m => (m.token === token ? m : false));

            return App.api.getGolfInfo(apolloClient, { id: matching.golfId })
              .then(({ golf }) => {
                document.title = (golf && golf.name) || 'prima.golf';

                // set locale
                i18n.locale = golf.locale;
                this.currency = golf.currency;

                // build session 3DS
                this.session3DS = {
                  address: {
                    city: golf.city,
                    countryCode: 250, // golf.country
                    line1: golf.name,
                    zipCode: golf.postalCode,
                  },
                  customer: {
                    email: account.email,
                    lastName: account.lastname,
                    firstName: account.firstname,
                  },
                };

                return Membership.api.getExercises(apolloClient, {
                  golfId: golf.id,
                  accountId: account.id,
                })
                  .then((data) => {
                    if (!data.exercises) {
                      throw new Error('Cotisation non trouvée');
                    }

                    const { exercises } = data.exercises;
                    const membership = exercises[0]
                      && exercises[0].memberships.find(m => m.paymentSchedule.length > 0);

                    if (!membership) {
                      throw new Error('Cotisation non trouvée');
                    }

                    return CreditCard.api.getCreditCards(apolloClient, { accountId: account.id })
                      .then(({ creditCards }) => {
                        this.setState({
                          golf,
                          account,
                          membership,
                          creditCard: creditCards[0] || null,
                        });
                      });
                  });
              });
          }
          throw new Error('Client non trouvé');
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ screenLoading: false }));
    }
  }

  /* */
  renderSchedule() {
    const { membership } = this.state;

    const { paymentSchedule: schedule } = membership;

    // const numberOfInstallments = schedule.length;
    // const first = schedule[0].date;
    // const last = schedule[numberOfInstallments - 1].date;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {'Date'}
              </TableCell>
              <TableCell align="right">
                {'Montant'}
              </TableCell>
              <TableCell align="right">
                {'Réglé'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule.map((l, i) =>
              <TableRow key={i}>
                <TableCell>
                  {i18n.l('date.formats.default', moment(l.date).toDate())}
                </TableCell>
                <TableCell align="right">
                  {i18n.l('currency', l.amount / 100)}
                </TableCell>
                <TableCell align="right">
                  {l.status === 1 && <DoneIcon color="primary" />}
                  {l.status === 2 && <CloseIcon color="error" />}
                </TableCell>
              </TableRow>)}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  /* */
  renderForm() {
    const { creditCard } = this.state;

    if (creditCard) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Alert variant="info">
            {'Vos prochains prélèvements seront effectués sur la carte bancaire ci-dessous.'}
          </Alert>
          <CreditCardPreview
            brand={creditCard.brand}
            number={creditCard.number}
            dueDate={creditCard.dueDate} />
        </Box>
      );
    }

    return (
      <Paper style={{ padding: 20 }}>
        <GetCreditCard
          currency={this.currency}
          session3DS={this.session3DS}
          onSubmit={this.handleSubmit}
          authorizationAmount={this.authorizationAmount} />
      </Paper>
    );
  }

  /* */
  handleSubmit = (values, { setSubmitting }) => {
    const { id3D = null } = values;
    const { account, membership, golf } = this.state;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      buttonLoading: true,
      success: null,
      status: 'Nous interrogeons votre banque...',
    });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      // Enregistrement cc
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        accountId: account.id,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(res => res.setCreditCard)
      .then((res) => {
        this.setState({ status: 'Nous confirmons votre plan de prélèvement au golf...' });

        // Appel API
        return Membership.api.setMembershipSchedule(apolloClient, {
          golfId: golf.id,
          creditCardId: res.id,
          year: membership.year,
          title: membership.year,
          amount: membership.total.amount,
          reference: membership.reference,
          paymentSchedule: membership.paymentSchedule.map(({ date, amount }) => ({ date, amount })),
          accountId: account.id,
          skipWS: true,
        })
          .then(() => this.setState({
            creditCard: res,
            success: 'Carte bancaire enregistrée avec succès.',
          }));
      })
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        setSubmitting(false);
        this.setState({ buttonLoading: false });
      });
  }

  renderHeader() {
    const { golf, account, membership } = this.state;

    const { paymentSchedule: schedule } = membership;
    const numberOfInstallments = schedule.length;
    const last = schedule[numberOfInstallments - 1].date;

    const total = {
      paid: membership.total.paid,
      amount: membership.total.amount,
      due: membership.total.amount - membership.total.paid,
    };

    const title = membership.members[0].category;

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          {golf.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {'Passage au prélèvement par carte bancaire'}
        </Typography>
        <br />
        <Typography variant="body2">
          {'Bonjour '}<b>{`${account.firstname} ${account.lastname}`}</b>{','}
        </Typography>
        <Typography variant="body2">
          {'Le réglement de votre cotisation '}<b>{title}</b>{' se fera dorénavant par carte bancaire.'}
        </Typography>
        <Typography variant="body2">
          {'Pour se faire, veuillez renseigner vos coordonnées bancaires dans le formulaire ci-dessous.'}
        </Typography>
        <br />
        <Typography gutterBottom color="textSecondary">
          {'Votre plan de paiement inchangé'}
          {` jusqu'au ${i18n.l('date.formats.default', moment(last).toDate())}`}
        </Typography>
        <Typography gutterBottom color="textSecondary">
          {`Montant déjà réglé : ${i18n.l('currency', total.paid / 100)}`}
        </Typography>
        <Typography gutterBottom color="textSecondary">
          {`Montant restant dû : ${i18n.l('currency', total.due / 100)}`}
        </Typography>
      </div>
    );
  }

  /* */
  render() {
    const {
      // ux
      error,
      status,
      success,
      buttonLoading,
      screenLoading,
      // data
      account,
      membership,
    } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        success={success}
        loading={screenLoading}>
        {account && membership && (
          <React.Fragment>
            {this.renderHeader()}
            <Grid
              container
              spacing={40}
              justify="center">
              <Grid item md={6}>
                {this.renderSchedule()}
              </Grid>
              <Grid item md={6}>
                {this.renderForm()}
              </Grid>
            </Grid>
            <DialogPaymentIP open={buttonLoading} status={status} />
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

export default ConfirmMembershipSchedule;
