import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  helpers,
  Paybox,
  CreditCard,
  Primaccess,
} from '@aps-management/primapp-common';
/* */
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = {};

/* */
class PrimaccessPayment extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      cart,
      golf,
      account,
    } = this.props;

    this.redirect = false;
    if (cart.length === 0) {
      this.redirect = true;
    }

    const uuid = Paybox.functions.createUuidFrom(account.id);

    this.data = {
      reference: `PA-${uuid}`,
      amount: cart.reduce((tot, product) => (tot + product.price), 0),
    };

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
      },
    };
    this.currency = golf.currency;

    this.state = {
      // Screen
      error: null,
      loading: true,
      // Payment
      status: null,
      imprint: null,
      waiting: false,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
        }

        this.setState(newState);
      });
  }

  /* */
  creditWallet() {
    const { transaction } = this.data;
    const { clientId, cart, golf } = this.props;

    this.props.update({ transaction });

    return Primaccess.api.createOperation(apolloClient, {
      clientId,
      transaction,
      golfId: golf.id,
      product: helpers.object.filterByKeys(cart[0], ['id', 'name', 'value', 'price']),
    })
      .then(() => this.goToVoucher());
  }

  /* */
  goToVoucher = () => this.props.history.push('/primaccess/voucher');

  /* */
  capture(creditCard) {
    const { account } = this.props;
    const { id3D = null } = creditCard;
    const { amount, reference } = this.data;

    if (creditCard.saveImprint) {
      const uuid = Paybox.functions.createUuidFrom(account.id);

      return Paybox.api.subscribeAndCapture(apolloClient, {
        uuid,
        id3D,
        amount,
        reference: account.id,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { handled: true });
        })
        .then(res => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['dueDate', 'country', 'brand', 'type']),
        })
          .then(() => res))
        .catch((err) => {
          if (!err.handled) {
            throw Object.assign(err, {
              handled: true,
              message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
            });
          }
          throw err;
        });
    }

    return Paybox.api.capture(apolloClient, {
      id3D,
      amount,
      reference,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    });
  }

  /* */
  payWithNewCard = (values, { setSubmitting }) => {
    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    return this.capture(creditCard)
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
        }
        throw err;
      })
      .then((res) => {
        this.data.transaction = {
          currency: this.currency,
          callNo: res.NUMAPPEL,
          amount: this.data.amount,
          transactionNo: res.NUMTRANS,
          card: CreditCard.functions.anonymizeNumber(creditCard.number),
          mode: 0,
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        };

        return this.creditWallet();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
        if (setSubmitting) setSubmitting(false);
      });
  }

  /* */
  payWithCardImprint = () => {
    const { imprint } = this.state;

    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    return Paybox.api.captureSubscriber(apolloClient, {
      ...this.data,
      subscriber,
    })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
        }
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        this.data.transaction = {
          currency: this.currency,
          card: imprint.number,
          callNo: res.NUMAPPEL,
          amount: this.data.amount,
          creditCardId: imprint.id,
          transactionNo: res.NUMTRANS,
          mode: 0,
          ...helpers.object.filterByKeys(imprint, ['type', 'brand', 'country']),
        };

        return this.creditWallet();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
      });
  }

  /* */
  render() {
    const {
      error,
      status,
      imprint,
      loading,
      waiting,
    } = this.state;
    const { amount } = this.data;

    if (this.redirect) {
      return <Redirect to="/primaccess" />;
    }

    return (
      <Screen
        error={error}
        loading={loading}
        title="Practice - Paiement par CB">
        <Payment
          amount={amount}
          imprint={imprint}
          currency={this.currency}
          session3DS={this.session3DS}
          payWithNewCard={this.payWithNewCard}
          payWithCardImprint={this.payWithCardImprint} />
        <DialogPaymentIP open={waiting} status={status} />
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: { account, golf },
  primaccess: { cart, clientId },
}) => ({
  cart,
  golf,
  account,
  clientId,
});

const StyledComponent = withStyles(styles)(PrimaccessPayment);

export default connect(
  mapStateToProps,
  Primaccess.actions,
)(StyledComponent);
