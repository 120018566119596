import React from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import { unstable_Box as Box } from '@material-ui/core/Box';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle';
import {
  App,
  Paybox,
  Account,
  helpers,
  Membership,
  CreditCard,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  Done as DoneIcon,
  Close as CloseIcon,
} from '@material-ui/icons';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import GetCreditCard from '_components/GetCreditCard';
import {
  Alert,
  DialogPaymentIP,
  CreditCardPreview,
} from '_components/elements';

/* */
class ConfirmMembershipSchedule extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.authorizationAmount = 1000;

    this.state = {
      // ux
      error: null,
      screenLoading: true,
      buttonLoading: false,
      // data
      golf: null,
      account: null,
      membership: null,
      currentCard: null, // carte existante (expirée ou non)
      selectedCard: null, // carte sélectionnée pour l'échéancier
      isFullyCompleted: false, // membershipSchedule existant - prêt pour prélèvement
    };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { reference } = this.props.match.params;
    const { token } = qs.parse(this.props.location.search);

    if (token && reference) {
      Account.api.getAccountByToken(apolloClient, { token })
        .then((account) => {
          if (account) {
            const matching = account.matches.find(m => (m.token === token ? m : false));

            return App.api.getGolfInfo(apolloClient, { id: matching.golfId })
              .then(({ golf }) => {
                document.title = (golf && golf.name) || 'prima.golf';

                // set locale
                i18n.locale = golf.locale;
                this.currency = golf.currency;

                // build session 3DS
                this.session3DS = {
                  address: {
                    city: golf.city,
                    countryCode: 250, // golf.country
                    line1: golf.name,
                    zipCode: golf.postalCode,
                  },
                  customer: {
                    email: account.email,
                    lastName: account.lastname,
                    firstName: account.firstname,
                  },
                };

                return Promise.all([
                  Membership.api.getExercises(apolloClient, {
                    golfId: golf.id,
                    accountId: account.id,
                  }),
                  Membership.api.checkMembershipSchedule(masterClient, {
                    reference,
                    golfId: golf.id,
                    accountId: account.id,
                  }),
                ])
                  .then((data) => {
                    if (!data[0].exercises) {
                      throw new Error('Cotisation non trouvée');
                    }

                    let membership = null;
                    const { exercises } = data[0].exercises;
                    exercises.forEach((e) => {
                      e.memberships.forEach((m) => {
                        if (m.reference === reference && m.paymentSchedule.length > 0) {
                          membership = m;
                        }
                      });
                    });
                    if (!membership) {
                      throw new Error('Cotisation non trouvée');
                    }

                    let isFullyCompleted = false;
                    if (data[1] && data[1].reference) {
                      isFullyCompleted = true;
                    }

                    return CreditCard.api.getCreditCards(apolloClient, { accountId: account.id })
                      .then(({ creditCards }) => {
                        let myCard = null;
                        if (creditCards[0]) {
                          myCard = {
                            ...creditCards[0],
                            ...CreditCard.functions.getStatus(creditCards[0].dueDate),
                          };
                        }

                        this.setState({
                          golf,
                          account,
                          membership,
                          isFullyCompleted,
                          currentCard: myCard,
                          selectedCard: myCard,
                        });
                      });
                  });
              });
          }
          throw new Error('Client non trouvé');
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ screenLoading: false }));
    }
  }

  /* */
  renderSchedule() {
    const { membership } = this.state;

    const { paymentSchedule: schedule } = membership;

    const total = {
      paid: membership.total.paid,
      amount: membership.total.amount,
      due: membership.total.amount - membership.total.paid,
    };

    return (
      <Box>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {'Date'}
                </TableCell>
                <TableCell align="right">
                  {'Montant'}
                </TableCell>
                <TableCell align="right">
                  {'Réglé'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schedule.map((l, i) =>
                <TableRow key={i}>
                  <TableCell>
                    {i18n.l('date.formats.default', moment(l.date).toDate())}
                  </TableCell>
                  <TableCell align="right">
                    {i18n.l('currency', l.amount / 100)}
                  </TableCell>
                  <TableCell align="right">
                    {l.status === 1 && <DoneIcon color="primary" />}
                    {l.status === 2 && <CloseIcon color="error" />}
                  </TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </Paper>
        <br />
        <Typography gutterBottom color="textSecondary">
          {`Montant déjà réglé : ${i18n.l('currency', total.paid / 100)}`}
        </Typography>
        <Typography gutterBottom color="textSecondary">
          {`Montant restant dû : ${i18n.l('currency', total.due / 100)}`}
        </Typography>
      </Box>
    );
  }

  /* */
  renderForm() {
    const {
      buttonLoading,
      currentCard,
      isFullyCompleted,
      selectedCard,
    } = this.state;

    if (!isFullyCompleted && selectedCard) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CreditCardPreview
            brand={selectedCard.brand}
            number={selectedCard.number}
            dueDate={selectedCard.dueDate} />
          <br />
          {selectedCard.isExpired && (
            <Alert variant="error">
              {'La carte est expirée.'}
            </Alert>
          )}
          {selectedCard.isSoonExpired && (
            <Alert variant="warning">
              {'La carte arrive bientôt à expiration.'}
            </Alert>
          )}
          {!selectedCard.isExpired ? (
            <React.Fragment>
              <Button
                color="secondary"
                variant="contained"
                disabled={buttonLoading}
                onClick={this.onCardConfirm}>
                {'Valider cette carte'}
              </Button>
              <br />
              <Button
                color="secondary"
                disabled={buttonLoading}
                onClick={() => this.setState({ selectedCard: null })}>
                {'Utiliser une autre carte'}
              </Button>
            </React.Fragment>
          ) : (
            <Button
              color="secondary"
              variant="contained"
              disabled={buttonLoading}
              onClick={() => this.setState({ selectedCard: null })}>
              {'Utiliser une autre carte'}
            </Button>
          )}
          <br />
          <Typography variant="caption">
            {'En cas de changement, la nouvelle carte bancaire sera aussi utilisée sur vos autres plans de prélèvement.'}
          </Typography>
        </Box>
      );
    }

    if (isFullyCompleted && selectedCard) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center">
          {!selectedCard.isExpired && (
            <React.Fragment>
              <CheckCircleOutlineIcon fontSize="large" color="primary" />
              <br />
              <Typography gutterBottom color="primary">
                {'Carte renseignée avec succès.'}
              </Typography>
            </React.Fragment>
          )}
          <CreditCardPreview
            brand={selectedCard.brand}
            number={selectedCard.number}
            dueDate={selectedCard.dueDate} />
          <br />
          {selectedCard.isExpired && (
            <Alert variant="error">
              {'La carte est expirée.'}
            </Alert>
          )}
          {selectedCard.isSoonExpired && (
            <Alert variant="warning">
              {'La carte arrive bientôt à expiration.'}
            </Alert>
          )}
          {(selectedCard.isExpired || selectedCard.isSoonExpired) && (
            <React.Fragment>
              <Button
                color="secondary"
                variant="contained"
                disabled={buttonLoading}
                onClick={() => this.setState({ selectedCard: null })}>
                {'Utiliser une autre carte'}
              </Button>
              <br />
              <Typography variant="caption">
                {'En cas de changement, la nouvelle carte bancaire sera aussi utilisée sur vos autres plans de prélèvement.'}
              </Typography>
            </React.Fragment>
          )}
          <br />
          <Typography>
            {'L\'ensemble des prélèvements effectués pour le compte de votre golf apparaîtront sous la mention "GOLF PRIMA" sur votre relevé bancaire.'}
          </Typography>
        </Box>
      );
    }

    // !isFullyCompleted && !selectedCard
    return (
      <Paper style={{ padding: 20 }}>
        <GetCreditCard
          currency={this.currency}
          session3DS={this.session3DS}
          onSubmit={this.onNewCardSubmit}
          authorizationAmount={this.authorizationAmount} />
        {currentCard && !selectedCard && (
          <Button
            fullWidth
            color="secondary"
            onClick={() => this.setState({ selectedCard: currentCard })}>
            {'Retour'}
          </Button>
        )}
      </Paper>
    );
  }

  // On confirme une carte existante et valide
  onCardConfirm = () => {
    const {
      account,
      membership,
      golf,
      selectedCard,
    } = this.state;

    this.setState({
      error: null,
      buttonLoading: true,
      status: 'Nous confirmons votre plan de prélèvement...',
    });

    return Membership.api.setMembershipSchedule(apolloClient, {
      golfId: golf.id,
      creditCardId: selectedCard.id,
      year: membership.year,
      title: membership.year,
      amount: membership.total.amount,
      reference: membership.reference,
      paymentSchedule: membership.paymentSchedule.map(({ date, amount }) => ({ date, amount })),
      accountId: account.id,
      skipWS: true,
    })
      .then(() => this.setState({ isFullyCompleted: true }))
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        this.setState({ buttonLoading: false });
      });
  }

  // On soumet une nouvelle carte ou une remplacante
  onNewCardSubmit = (values, { setSubmitting }) => {
    const { id3D = null } = values;
    const {
      account,
      membership,
      golf,
      isFullyCompleted,
    } = this.state;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      buttonLoading: true,
      status: 'Nous interrogeons votre banque...',
    });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        accountId: account.id,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(res => res.setCreditCard)
      .then((res) => {
        // si un plan de prélèvement existait déjà
        // dans ce cas inutile d'en recréer un
        // la nouvelle carte a été associée grâce au setCreditCard
        if (isFullyCompleted) {
          return Promise.resolve()
            .then(() => this.setState({ selectedCard: res }));
        }

        this.setState({ status: 'Nous confirmons votre plan de prélèvement...' });

        return Membership.api.setMembershipSchedule(apolloClient, {
          golfId: golf.id,
          creditCardId: res.id,
          year: membership.year,
          title: membership.year,
          amount: membership.total.amount,
          reference: membership.reference,
          paymentSchedule: membership.paymentSchedule.map(({ date, amount }) => ({ date, amount })),
          accountId: account.id,
          skipWS: true,
        })
          .then(() => this.setState({
            selectedCard: res,
            isFullyCompleted: true,
          }));
      })
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        setSubmitting(false);
        this.setState({ buttonLoading: false });
      });
  }

  renderHeader() {
    const { golf, account, membership } = this.state;

    const title = membership.members[0].category;

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          {golf.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {'Prélèvement par carte bancaire'}
        </Typography>
        <br />
        <Typography variant="body2">
          {'Bonjour '}<b>{`${account.firstname} ${account.lastname}`}</b>{','}
        </Typography>
        <Typography variant="body2">
          {'Le réglement de votre cotisation '}<b>{title}</b>{' se fera par prélèvement sur votre carte bancaire.'}
        </Typography>
        <Typography variant="body2">
          {'Pour ce faire, veuillez valider vos coordonnées bancaires ci-dessous.'}
        </Typography>
        <br />
      </div>
    );
  }

  /* */
  render() {
    const {
      // ux
      error,
      status,
      buttonLoading,
      screenLoading,
      // data
      account,
      membership,
    } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        loading={screenLoading}>
        {account && membership && (
          <React.Fragment>
            {this.renderHeader()}
            <Grid
              container
              spacing={40}
              justify="center">
              <Grid item md={6}>
                <Typography variant="h6">
                  {'Mon plan de paiement'}
                </Typography>
                <br />
                {this.renderSchedule()}
              </Grid>
              <Grid item md={6}>
                <Typography variant="h6">
                  {'Ma carte bancaire'}
                </Typography>
                <br />
                {this.renderForm()}
              </Grid>
            </Grid>
            <DialogPaymentIP open={buttonLoading} status={status} />
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

export default ConfirmMembershipSchedule;
