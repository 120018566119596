import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  helpers,
  Paybox,
  Membership,
  CreditCard,
} from '@aps-management/primapp-common';
/* */
import i18n from '_utils/i18n';
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = {};

/* */
class MembershipPayment extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      golf,
      account,
      data: { due },
    } = this.props;

    this.redirect = false;
    if (due.memberships.length === 0) {
      this.redirect = true;
    }

    const {
      id,
      email,
      firstname,
      lastname,
    } = account;
    due.golf = golf;
    due.accountId = id;
    due.owner = { email, name: `${firstname} ${lastname}` };

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
      },
    };
    this.currency = golf.currency;
    this.authorizationAmount = 1000;

    this.state = {
      // Screen
      error: null,
      loading: true,
      // Payment
      status: null,
      imprint: null,
      waiting: false,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
        }

        this.setState(newState);
      });
  }

  /* */
  getMembershipTitle = (year) => {
    const { wording } = this.props;

    return `${helpers.string.ucfirst(i18n.t(`terms.membership_${wording.membership}`))} ${year}`;
  }

  /* */
  setMembershipPayments() {
    const { data: { due } } = this.props;

    return Promise.all(
      due.memberships.map(m => Membership.api.setMembershipPayment(apolloClient, {
        year: m.year,
        golfId: due.golf.id,
        reference: m.reference,
        transaction: m.transactionPaybox,
        title: this.getMembershipTitle(m.year),
      })),
    );
  }

  /* */
  setMembershipSchedules(creditCardId) {
    const { data: { due } } = this.props;

    return Promise.all(
      due.memberships.map(m => Membership.api.setMembershipSchedule(apolloClient, {
        creditCardId,
        year: m.year,
        amount: m.amount,
        golfId: due.golf.id,
        reference: m.reference,
        title: this.getMembershipTitle(m.year),
        paymentSchedule: m.transaction.schedule,
      })),
    );
  }

  /* */
  payWithNewCard = (values, { setSubmitting }) => {
    const { id3D = null } = values;
    const { data: { due } } = this.props;

    // Unix ms timestamp, must be unique
    const uuid = Paybox.functions.createUuidFrom(due.accountId);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    let payPromise;

    // Paiement comptant
    if (due.cash) {
      // Capture Paybox
      payPromise = this.captureNewCard({
        id3D,
        uuid,
        creditCard,
        amount: due.total,
        reference: `MEMBERSHIP-${due.memberships.map(m => m.reference).join('-')}`,
      })
        .catch((err) => {
          throw Object.assign(err, { handled: true });
        })
        .then((res) => {
          this.setState({ status: 'Nous confirmons votre paiement au golf...' });

          due.memberships.forEach((m, i) => {
            due.memberships[i].transactionPaybox = {
              amount: m.amount,
              callNo: res.NUMAPPEL,
              transactionNo: res.NUMTRANS,
              card: CreditCard.functions.anonymizeNumber(creditCard.number),
              ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
            };
          });

          // Appel API
          return this.setMembershipPayments();
        })
        .catch((err) => {
          // Paybox capture is OK
          if (!err.handled) {
            // Go to voucher with API error
            due.apiError = true;
            return {};
          }
          throw err;
        });
    // Paiment échelonné
    } else {
      // Création abonné Paybox
      payPromise = Paybox.api.createSubscriber(apolloClient, {
        id3D,
        uuid,
        // amount: due.total,
        reference: due.accountId,
        amount: this.authorizationAmount,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { handled: true });
        })
        // Enregistrement cc
        .then(res => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
        }))
        .catch((err) => {
          if (!err.handled) {
            throw Object.assign(err, { handled: true, message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.' });
          }
          throw err;
        })
        .then(res => res.setCreditCard)
        .then((res) => {
          this.setState({ status: 'Nous confirmons votre plan de prélèvement au golf...' });

          // Appel API
          return this.setMembershipSchedules(res.id);
        });
    }

    return payPromise
      .then(() => this.props.history.push('/membership/voucher'))
      .catch((err) => {
        this.setState({
          status: null,
          waiting: false,
          error: err.message,
        });
        setSubmitting(false);
      });
  }

  /* */
  captureNewCard({
    id3D,
    uuid,
    amount,
    reference,
    creditCard,
  }) {
    if (creditCard.saveImprint) {
      return Paybox.api.subscribeAndCapture(apolloClient, {
        uuid,
        id3D,
        amount,
        reference,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { handled: true });
        })
        .then(res => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'country', 'brand', 'type']),
        })
          .then(() => res))
        .catch((err) => {
          if (!err.handled) {
            throw Object.assign(err, {
              handled: true,
              message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
            });
          }
          throw err;
        });
    }

    return Paybox.api.capture(apolloClient, {
      id3D,
      amount,
      reference,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    });
  }

  /* */
  payWithCardImprint = () => {
    const { imprint } = this.state;
    const { data: { due } } = this.props;

    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    this.setState({ error: null, waiting: true });

    let payPromise;

    // Paiement comptant
    if (due.cash) {
      this.setState({ status: 'Nous interrogeons votre banque...' });

      // Capture abonné Paybox
      payPromise = Paybox.api.captureSubscriber(apolloClient, {
        subscriber,
        amount: due.total,
        reference: `MEMBERSHIP-${due.memberships.map(m => m.reference).join('-')}`,
      })
        .catch((err) => {
          if (!err.handled) {
            this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
          }
          throw Object.assign(err, { handled: true });
        })
        .then((res) => {
          this.setState({ status: 'Nous confirmons votre paiement au golf...' });

          due.memberships.forEach((m, i) => {
            due.memberships[i].transactionPaybox = {
              amount: m.amount,
              card: imprint.number,
              callNo: res.NUMAPPEL,
              creditCardId: imprint.id,
              transactionNo: res.NUMTRANS,
              ...helpers.object.filterByKeys(imprint, ['type', 'brand', 'country']),
            };
          });

          // Appel API
          return this.setMembershipPayments();
        })
        .catch((err) => {
          // Paybox capture is OK
          if (!err.handled) {
            // Go to voucher with API error
            due.apiError = true;
            return {};
          }
          throw err;
        });
    // Paiement échelonné
    } else {
      this.setState({ status: 'Nous confirmons votre plan de prélèvement au golf...' });

      // Appel API
      payPromise = this.setMembershipSchedules(imprint.id);
    }

    return payPromise
      .then(() => this.props.history.push('/membership/voucher'))
      .catch((err) => {
        this.setState({
          status: null,
          waiting: false,
          error: err.message,
        });
      });
  }

  /* */
  render() {
    const { data: { due } } = this.props;
    const {
      error,
      status,
      imprint,
      loading,
      waiting,
    } = this.state;

    if (this.redirect) {
      return <Redirect to="/membership" />;
    }

    const numberOfInstallments = due.cash ? 1 : due.memberships[0].transaction.schedule.length;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Cotisations - Paiement par CB">
        <Payment
          amount={due.total}
          imprint={imprint}
          currency={this.currency}
          session3DS={this.session3DS}
          payWithNewCard={this.payWithNewCard}
          numberOfInstallments={numberOfInstallments}
          payWithCardImprint={this.payWithCardImprint} />
        <DialogPaymentIP open={waiting} status={status} />
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, membershipData }) => ({
  golf: app.golf,
  account: app.account,
  data: membershipData,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(MembershipPayment);

export default connect(
  mapStateToProps,
  Membership.actions,
)(StyledComponent);
