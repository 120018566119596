import React from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { App, Membership } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  List,
  Grid,
  Paper,
  Button,
  Checkbox,
  ListItem,
  Typography,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  sumarryTotal: {
    paddingTop: `${theme.spacing.unit * 1}px`,
    paddingRight: `${theme.spacing.unit * 2}px`,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  alignRight: {
    textAlign: 'right',
  },
  section: {
    marginBottom: `${theme.spacing.unit * 3}px`,
  },
});

/* */
class Subscribe extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // Screen
      error: null,
      loading: true,
      // UI
      waiting: false,
      // Data
      memberships: [],
      selectedMain: null,
      selectedAdditionals: [],
      selectedAccessories: [],
      selectedPeriodCode: null,
    };

    const { redirect = null } = qs.parse(props.location.search);
    this.redirectTo = redirect;
  }

  /* */
  componentDidMount() {
    const { golf } = this.props;

    // Get memberships
    Membership.api.getMemberships(apolloClient, { golfId: golf.id })
      .then((memberships) => {
        let selectedAdditionnals = [];
        if (memberships.additional) {
          selectedAdditionnals = memberships.additional.find(a => a.required);
        }
        this.setState({ memberships, selectedAdditionnals });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleSubmit = () => {
    const { golf, account } = this.props;
    const {
      selectedMain,
      selectedPeriodCode,
      selectedAdditionals,
      selectedAccessories,
    } = this.state;

    if (!selectedMain) return null;

    this.setState({
      // Screen
      error: null,
      waiting: true,
    });

    const params = {
      golfId: golf.id,
      main: { code: selectedMain },
      periodCode: selectedPeriodCode,
      additional: selectedAdditionals.map(code => ({ code })),
      accessories: selectedAccessories.map(code => ({ code })),
    };

    const redirectRoute = this.redirectTo
      ? `/membership?redirect=${this.redirectTo}`
      : '/membership';

    return Membership.api.subscribe(apolloClient, params)
      .then(() => this.props.loadAccount(apolloClient, { id: account.id }))
      .then(() => this.props.history.push(redirectRoute))
      .catch(e => this.setState({ error: e.message, waiting: false }));
  }

  /* */
  handleToggle = (m, type) => () => {
    const {
      selectedAdditionals,
      selectedAccessories,
    } = this.state;

    // Main
    if (type === 'main') {
      this.setState({
        selectedMain: m.code,
        selectedPeriodCode: (m.options && m.options[0].code) || '01',
      });
    // Additionals
    } else if (type === 'additionnal') {
      const curIdx = selectedAdditionals.indexOf(m.code);
      const newAdditionnals = [...selectedAdditionals];

      if (curIdx === -1) {
        newAdditionnals.push(m.code);
      } else {
        newAdditionnals.splice(curIdx, 1);
      }

      this.setState({
        selectedAdditionals: newAdditionnals,
      });
    // Accessories
    } else {
      const curIdx = selectedAccessories.indexOf(m.code);
      const newAccessories = [...selectedAccessories];

      if (curIdx === -1) {
        newAccessories.push(m.code);
      } else {
        newAccessories.splice(curIdx, 1);
      }

      this.setState({
        selectedAccessories: newAccessories,
      });
    }
  }

  /* */
  renderMain = (m) => {
    const { classes } = this.props;
    const { selectedMain } = this.state;

    const checked = selectedMain === m.code;

    return (
      <ListItem
        button
        divider
        key={m.code}
        disableRipple
        onClick={this.handleToggle(m, 'main')}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          classes={{ root: classes.checkbox }} />
        <ListItemText
          primary={m.name}
          secondary={`${m.options[0].period} mois`} />
        <Typography>{i18n.l('currency', m.options[0].price / 100)}</Typography>
      </ListItem>
    );
  }

  /* */
  renderAdditional = (m) => {
    const { classes } = this.props;
    const { selectedAdditionals } = this.state;

    const checked = selectedAdditionals.includes(m.code);

    return (
      <ListItem
        button
        divider
        key={m.code}
        disableRipple
        onClick={this.handleToggle(m, 'additionnal')}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          classes={{ root: classes.checkbox }} />
        <ListItemText
          primary={m.name}
          secondary={`${m.options[0].period} mois`} />
        <Typography>{i18n.l('currency', m.options[0].price / 100)}</Typography>
      </ListItem>
    );
  }

  /* */
  renderAccs = (m) => {
    const { classes } = this.props;
    const { selectedAccessories } = this.state;

    const checked = selectedAccessories.includes(m.code);

    return (
      <ListItem
        button
        divider
        key={m.code}
        disableRipple
        onClick={this.handleToggle(m, 'accs')}>
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          classes={{ root: classes.checkbox }} />
        <ListItemText
          primary={m.name} />
        <Typography>{i18n.l('currency', m.price / 100)}</Typography>
      </ListItem>
    );
  }

  /* */
  renderSummary() {
    const { classes } = this.props;
    const {
      waiting,
      memberships,
      selectedMain,
      selectedAdditionals,
      selectedAccessories,
    } = this.state;

    if (!selectedMain) return null;

    const summary = [];
    memberships.main.forEach(m => m.code === selectedMain
      && summary.push({ name: m.name, price: m.options[0].price }));
    memberships.additional.forEach(m => selectedAdditionals.includes(m.code)
      && summary.push({ name: m.name, price: m.options[0].price }));
    memberships.accessories.forEach(m => selectedAccessories.includes(m.code)
      && summary.push({ name: m.name, price: m.price }));

    const total = summary.reduce((counter, line) => counter + line.price, 0);

    return (
      <React.Fragment>
        <Typography
          paragraph
          variant="h6"
          component="h2">
          {'Résumé'}
        </Typography>
        <List dense disablePadding>
          {summary.map((item, key) => (
            <ListItem
              divider
              key={key}
              classes={{ root: classes.listItem }}>
              <ListItemText
                classes={{ secondary: classes.alignRight }}
                primary={item.name}
                secondary={i18n.l('currency', item.price / 100)} />
            </ListItem>
          ))}
        </List>
        {summary.length > 1 && (
          <Typography
            gutterBottom
            variant="h6"
            align="right"
            className={classes.sumarryTotal}>
            {i18n.l('currency', total / 100)}
          </Typography>
        )}
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          disabled={waiting}
          onClick={this.handleSubmit}>
          {waiting
            ? <CircularProgress color="inherit" size={24} />
            : 'Valider'}
        </Button>
      </React.Fragment>
    );
  }

  /* */
  renderContent() {
    const { classes } = this.props;
    const { memberships } = this.state;

    const {
      main = [],
      additional = [],
      accessories = [],
    } = memberships;

    if (!main || main.length === 0) return null;

    return (
      <React.Fragment>
        <Typography
          paragraph
          variant="h6"
          component="h2">
          {'Cotisation principale'}
        </Typography>
        <Paper className={classes.section}>
          <List disablePadding>{main.map(this.renderMain)}</List>
        </Paper>
        {additional.length > 0 && (
          <React.Fragment>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Compléments'}
            </Typography>
            <Paper className={classes.section}>
              <List disablePadding>{additional.map(this.renderAdditional)}</List>
            </Paper>
          </React.Fragment>
        )}
        {accessories.length > 0 && (
          <React.Fragment>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Accessoires'}
            </Typography>
            <Paper className={classes.section}>
              <List disablePadding>{accessories.map(this.renderAccs)}</List>
            </Paper>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title={"S'abonner"}>
        <Grid container spacing={24} justify="center">
          <Grid item md={8} xs={12}>
            {this.renderContent()}
          </Grid>
          <Grid item md={4} xs={12}>
            {this.renderSummary()}
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
  wording: app.golf.options.wording,
});

const mapDispatchToProps = { ...App.actions, ...Membership.actions };

const StyledComponent = withStyles(styles)(Subscribe);

export default connect(mapStateToProps, mapDispatchToProps)(StyledComponent);
