import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FaceIcon from '@material-ui/icons/Face';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  App,
  helpers,
  Booking,
  HELP_URL,
} from '@aps-management/primapp-common';
import {
  Grid,
  List,
  Paper,
  Avatar,
  Button,
  Dialog,
  ListItem,
  Typography,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  ListSubheader,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  avatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.text.primary,
  },
  header: {
    overflow: 'hidden',
    position: 'relative',
    boxShadow: theme.shadows[2],
    backgroundColor: 'transparent',
  },
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  price: {
    color: theme.palette.text.secondary,
  },
  initPrice: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
    marginLeft: theme.spacing.unit * 1.5,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  canceled: {
    top: 16,
    right: -90,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  cancelBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

/* */
const getPlayerName = player => ((player.firstname && player.lastname)
  ? `${player.firstname} ${player.lastname}`
  : helpers.string.ucfirst(i18n.t('terms.anonymous')));

/* */
const getLastStatus = booking => ((booking.status && booking.status.length)
  ? booking.status[0].status
  : null
);

/* */
class BookingView extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      golf,
      account,
      match: { params },
    } = this.props;

    this.id = params.id;
    this.source = this.id ? 'db' : 'ws';

    this.permissions = null;

    const match = App.functions.match(account, golf);
    this.accountReference = (match && match.reference) || null;

    this.state = {
      // Data
      open: false,
      booking: null,
      waiting: false,
      cancelType: null,
      // Screen
      error: null,
      loading: true,
      success: null,
      redirect: false,
    };
  }

  /* */
  componentDidMount() {
    const { details, golf } = this.props;

    let promise;
    if (this.source === 'db') {
      promise = Booking.api.getBooking(apolloClient, this.id);
    } else if (details) {
      promise = Promise.resolve({ booking: details });
    } else {
      this.setState({ redirect: true });
      return;
    }

    promise
      .then(({ booking }) => {
        const isFree = booking.total ? booking.total.price === 0 : true;
        const checkLastStatus = ['updated', 'confirmed'].includes(getLastStatus(booking));
        const deadline = moment(`${booking.date} ${booking.time}`, 'YYYY-MM-DD HHmm');// .subtract('1', 'hour');

        // init rigths
        const permissions = {
          edit: false,
          cancelAll: false,
          cancelMine: false,
        };

        // check no actions
        if (deadline.isAfter()) {
          if (this.source === 'db' && checkLastStatus) {
            if (isFree) {
              permissions.edit = true;
              permissions.cancelAll = true;
            // sur place ou compte client
            } else if ([1, 2].includes(booking.paymentMethod)) {
              permissions.cancelAll = true;
            }
          } else if (this.source === 'ws' && isFree) {
            permissions.cancelMine = !booking.isLocked;
          }
          // Empêcher la modification
          if (golf.options.booking?.disableModification) {
            permissions.edit = false;
          }
        }
        const anyPermission = Object.keys(permissions).every(k => !permissions[k]);
        if (!anyPermission) { this.permissions = permissions; }

        this.setState({ booking });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleClickOpen = cancelType => () => {
    this.setState({ open: true, cancelType });
  };

  /* */
  handleClose = () => {
    this.setState({ open: false });
  };

  /* */
  doCancelRequest = () => {
    const { history } = this.props;
    const { booking } = this.state;

    this.setState({
      error: null,
      waiting: true,
      success: null,
    });

    if (this.source === 'ws') {
      const {
        golf,
        course,
        players,
        bookingId,
        accessories,
      } = booking;

      // detect user account in players ws
      const idx = players.findIndex(p => p.tid === this.accountReference);
      players[idx].todo = 'cancel';
      players[idx].bookingId = bookingId;

      const params = {
        id: null,
        date: booking.date,
        time: booking.time,
        golf: {
          id: golf.id,
        },
        course: {
          id: course.id,
          type: course.type,
          name: course.name,
        },
        players: players.map(p => ({
          tid: p.tid,
          email: p.email,
          lastname: p.lastname,
          todo: p.todo || null,
          firstname: p.firstname,
          bookingId: p.bookingId || null,
        })),
        accessories: accessories.map(acc => ({
          qty: acc.qty,
          name: acc.name,
        })),
      };

      Booking.api.bookingEdit(apolloClient, params)
      // Promise.resolve()
        .then(() => {
          this.setState({
            success: 'Votre départ a été annulé avec succès.',
          });
          history.push('/mybookings');
          this.props.detailBooking(null); // reset
        })
        .catch(e => this.setState({
          open: false,
          waiting: false,
          error: e.message,
        }));
    } else {
      Booking.api.bookingCancel(apolloClient, { id: this.id })
        .then(({ status }) => this.setState({
          booking: {
            ...this.state.booking,
            status: [
              { status, date: moment().format() },
              ...this.state.booking.status,
            ],
          },
          success: 'Votre réservation a été annulée avec succès.',
        }))
        .catch(e => this.setState({ error: e.message }))
        .finally(() => this.setState({
          open: false,
          waiting: false,
        }));
    }
  }

  /* */
  goToEdit = () => {
    const { booking } = this.state;

    this.props.detailBooking(booking);
    this.props.history.push(`/mybookings/${booking.id}/edit`);
  }

  /* */
  renderHeader() {
    const { booking } = this.state;
    const { classes } = this.props;

    const lastStatus = getLastStatus(booking);
    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(booking.date)));
    const courseName = `${i18n.t(`terms.course_type_${booking.course.type}`)} "${booking.course.name}"`;

    return (
      <Box
        py={2}
        mb={2}
        className={classes.header}>
        {lastStatus && lastStatus === 'canceled' && (
          <Typography
            component="span"
            variant='caption'
            className={classes.canceled}>
            {'Annulée'}
          </Typography>
        )}
        <Typography
          align="center"
          color="textSecondary"
          component="h2"
          variant="h5">
          {booking.golf.name}
        </Typography>
        <Typography
          align="center"
          component="p"
          variant="h5">
          {`${dateTxt} • ${Booking.functions.formatTime(booking.time)}`}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
      </Box>
    );
  }

  /* */
  renderActions() {
    const { permissions } = this;
    const { classes } = this.props;
    const { booking, waiting } = this.state;

    if (!permissions || getLastStatus(booking) === 'canceled') return null;

    return (
      <Box mb={2}>
        {this.source === 'ws' && (
          <Alert variant="info">
            {'Ce départ a été pris pour vous. Seul votre départ peut être annulé.'}
          </Alert>
        )}
        <Box
          display="flex"
          justifyContent="center">
          {permissions.edit && !waiting && (
            <Button
              color="secondary"
              variant="contained"
              style={{ marginRight: 16 }}
              onClick={this.goToEdit}>
              {'Modifier'}
            </Button>
          )}
          {permissions.cancelAll && (
            <Button
              disabled={waiting}
              variant="contained"
              className={classes.cancelBtn}
              onClick={this.handleClickOpen('all')}>
              {waiting
                ? <CircularProgress color="inherit" size={24} />
                : 'Annuler la réservation'}
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  /* */
  renderPlayers() {
    const { classes } = this.props;
    const { permissions, accountReference } = this;
    const { booking: { players }, waiting } = this.state;

    if (players.length === 0) return null;

    const subtotal = players.reduce((counter, line) => counter + (line.price || 0), 0);
    const showSubtotal = this.source === 'db' && players.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {i18n.t('terms.players', { count: players.length })}
        </Typography>
        <Paper>
          <List disablePadding>
            {players.map((pl, key) => (
              <ListItem divider key={key}>
                <Avatar className={classes.avatar}>
                  <FaceIcon />
                </Avatar>
                <ListItemText primary={getPlayerName(pl)} />
                {pl.price > 0 && (
                  <Typography
                    className={classes.price}
                    component="span"
                    variant="subtitle1">
                    {i18n.l('currency', pl.price / 100)}
                  </Typography>
                )}
                {pl.discount > 0 && (
                  <Typography
                    className={classes.initPrice}
                    component="span"
                    variant="body2">
                    {i18n.l('currency', pl.initPrice / 100)}
                  </Typography>
                )}
                {pl.price === 0 && (
                  <Typography component="span" variant="subtitle1">
                    {i18n.t('terms.included')}
                  </Typography>
                )}
                {permissions
                  && permissions.cancelMine
                  && pl.tid === accountReference && (
                  <Button
                    disabled={waiting}
                    variant="contained"
                    className={classes.cancelBtn}
                    onClick={this.handleClickOpen('mine')}>
                    {waiting
                      ? <CircularProgress color="inherit" size={24} />
                      : 'Supprimer'}
                  </Button>
                )}
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderAccessories() {
    const { classes } = this.props;
    const { booking: { accessories } } = this.state;

    if (accessories.length === 0) return null;

    const subtotal = accessories.reduce((counter, line) => counter + (line.price || 0), 0);
    const showSubtotal = this.source === 'db' && accessories.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {i18n.t('terms.rentals', { count: accessories.length })}
        </Typography>
        <Paper>
          <List disablePadding>
            {accessories.map((acc, key) => (
              <ListItem divider key={key}>
                <Avatar className={classes.avatar}>{acc.qty}</Avatar>
                <ListItemText primary={acc.name} />
                {acc.price > 0 && (
                  <Typography
                    className={classes.price}
                    component="span"
                    variant="subtitle1">
                    {i18n.l('currency', acc.price / 100)}
                  </Typography>
                )}
                {acc.price === 0 && (
                  <Typography component="span" variant="subtitle1">
                    {i18n.t('terms.included')}
                  </Typography>
                )}
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderTotal() {
    const { classes } = this.props;
    const { booking } = this.state;

    if (!booking.total || booking.total.price === 0) return null;

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify={booking.accessories.length === 0 ? 'center' : 'flex-end'}>
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Paper>
            <List
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  color="inherit">
                  {'Total'}
                </ListSubheader>}
              disablePadding>
              <ListItem divider style={{ alignItems: 'baseline' }}>
                <ListItemText primary={'Montant'} />
                <Typography
                  className={classes.price}
                  component="span"
                  variant="subtitle1">
                  {i18n.l('currency', booking.total.price / 100)}
                </Typography>
                {booking.total.discount > 0 && (
                  <Typography
                    className={classes.initPrice}
                    component="span"
                    variant="body2">
                    {i18n.l('currency', booking.total.initPrice / 100)}
                  </Typography>
                )}
              </ListItem>
              {booking.total.discount > 0 && (
                <ListItem divider>
                  <ListItemText primary={'Remise'} />
                  <Typography component="span" variant="subtitle1">
                    {i18n.toPercentage(
                      -1 * (booking.total.discount / booking.total.initPrice * 100),
                      { precision: 0 },
                    )}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderCancelDialog() {
    const { classes } = this.props;
    const { waiting, open, cancelType } = this.state;

    const title = (cancelType === 'all')
      ? 'Êtes-vous sûr de vouloir annuler votre réservation ?'
      : 'Êtes-vous sûr de vouloir annuler votre départ ?';

    return (
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.handleClose}>
        <DialogTitle>{'Annulation'}</DialogTitle>
        <DialogContent>
          <DialogContentText color="default">
            {title}
          </DialogContentText>
          <DialogContentText color="default" variant="caption">
            {'Un e-mail de confirmation sera envoyé aux autres joueurs.'}<br />
            {cancelType === 'mine'
              && 'Seul votre départ sera annulé. Celui-ci ne sera plus présent dans "Mes réservations".'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={this.handleClose}>
            {'Non'}
          </Button>
          <Button
            color="inherit"
            disabled={waiting}
            variant="contained"
            className={classes.cancelBtn}
            onClick={this.doCancelRequest}>
            {waiting
              ? <CircularProgress color="inherit" size={24} />
              : 'Oui'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  renderStatus() {
    const { classes } = this.props;
    const { booking } = this.state;

    if (this.source !== 'db') return null;

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify={booking.accessories.length === 0 ? 'center' : 'flex-end'}>
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Paper>
            <List
              disablePadding
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  color="inherit">
                  {'Informations'}
                </ListSubheader>}>
              {booking.status
                .filter(({ status }) => status !== 'booked')
                .map((item, key) => (
                  <ListItem divider key={key}>
                    <ListItemText primary={i18n.t(`terms.status_${item.status}`)} />
                    <Typography component="span" variant="subtitle1">
                      {`${i18n.l('time.formats.default', item.date)}`}
                    </Typography>
                  </ListItem>
                ))}
              {booking.paymentMethod === 1 && (
                <ListItem divider>
                  <ListItemText primary={'Moyen de paiement'} />
                  <Typography component="span" variant="subtitle1">
                    {'Sur place'}
                  </Typography>
                </ListItem>
              )}
              {booking.paymentMethod === 2 && (
                <ListItem divider>
                  <ListItemText primary={'Moyen de paiement'} />
                  <Typography component="span" variant="subtitle1">
                    {'Par compte client'}
                  </Typography>
                </ListItem>
              )}
              {booking.payments && booking.payments[0] && (
                <React.Fragment>
                  {booking.payments[0].card && (
                    <ListItem divider alignItems="flex-start">
                      <ListItemText primary={'Moyen de paiement'} />
                      <Box>
                        <Typography component="span" variant="subtitle1">
                          {'Carte bancaire'}
                        </Typography>
                        <Typography
                          component="span"
                          color="textSecondary"
                          variant="subtitle1">
                          {booking.payments[0].card}
                        </Typography>
                      </Box>
                    </ListItem>
                  )}
                  <ListItem divider>
                    <ListItemText primary={'E-ticket'} />
                    <Typography component="span" variant="subtitle1">
                      {booking.payments[0].transactionNo}
                    </Typography>
                  </ListItem>
                </React.Fragment>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  /* */
  render() {
    const {
      error,
      booking,
      loading,
      success,
      redirect,
    } = this.state;

    if (redirect) {
      return <Redirect to="/mybookings" />;
    }

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title="Ma réservation"
        onBackPress={() => this.props.history.goBack()}
        helpURL={`${HELP_URL}/mes-r%C3%A9servations#h.p_zI6UzSITkgym`}>
        {booking && (
          <React.Fragment>
            {this.renderHeader()}
            {this.renderActions()}
            <Grid container spacing={24} justify="center">
              {this.renderPlayers()}
              {this.renderAccessories()}
            </Grid>
            {this.renderTotal()}
            {this.renderStatus()}
            {this.renderCancelDialog()}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, bookingData: { details } }) => ({
  details,
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(BookingView);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
