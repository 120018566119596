import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  helpers,
  Paybox,
  Booking,
  CreditCard,
} from '@aps-management/primapp-common';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/* */
import i18n from '_utils/i18n';
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = {};

/* */
class BookingPayment extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      golf,
      account,
      data: { booking },
    } = this.props;

    this.redirect = false;
    if (booking === null) {
      this.redirect = true;
      return;
    }

    this.amount = booking.total.price;
    this.currency = booking.total.currency;

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
      },
    };

    this.state = {
      error: null,
      status: null,
      loading: true,
      waiting: false,
      expanded: null,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
          expanded: 'other',
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
          newState.expanded = 'imprint';
        }

        this.setState(newState);
      });
  }

  /* */
  payWithCardImprint = () => {
    const { imprint } = this.state;
    const { booking } = this.props.data;

    const subscriber = {
      id: imprint.id,
      token: imprint.token,
      dueDate: imprint.dueDate,
    };

    const data = {};

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous prenons votre réservation au golf...',
    });

    return Booking.api.bookingBook(apolloClient, booking)
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        data.id = res.id;
        data.amount = res.total.price;
        this.setState({ status: 'Nous interrogeons votre banque...' });

        return Paybox.api.captureSubscriber(apolloClient, {
          amount: data.amount,
          reference: data.id,
          subscriber,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });
          return Booking.api.bookingCancel(apolloClient, { id: data.id, failed: true })
            .then(() => { throw Object.assign(err, { handled: true }); })
            .catch(() => { throw Object.assign(err, { handled: true }); });
        }
        throw err;
      })
      .then((res) => {
        data.transaction = {
          amount: data.amount,
          card: imprint.number,
          callNo: res.NUMAPPEL,
          creditCardId: imprint.id,
          transactionNo: res.NUMTRANS,
          ...helpers.object.filterByKeys(imprint, ['type', 'brand', 'country']),
        };

        return Booking.api.bookingConfirm(apolloClient, {
          id: data.id,
          transaction: data.transaction,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          return data;
        }
        this.setState({ error: err.message, waiting: false });
        throw err;
      })
      .then(({ transaction }) => {
        this.props.updateBooking({
          ...data,
          transaction,
        });
        this.props.history.replace('/booking/voucher');
      })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ error: err.message, waiting: false });
        }
      });
  }

  /* */
  capture(data, creditCard) {
    const { account } = this.props;

    if (creditCard.saveImprint) {
      const uuid = Paybox.functions.createUuidFrom(account.id);

      return Paybox.api.subscribeAndCapture(apolloClient, {
        uuid,
        id3D: data.id3D,
        amount: data.amount,
        reference: account.id,
        creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
      })
        .catch((err) => {
          throw Object.assign(err, { onSubscribe: true });
        })
        .then(res => CreditCard.api.setCreditCard(apolloClient, {
          id: uuid,
          token: res.PORTEUR,
          number: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'country', 'brand', 'type']),
        })
          .then(() => res))
        .catch((err) => {
          if (!err.onSubscribe) {
            throw Object.assign(err, {
              message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
            });
          }
          throw err;
        });
    }

    return Paybox.api.capture(apolloClient, {
      id3D: data.id3D,
      reference: data.id,
      amount: data.amount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    });
  }

  /* */
  payWithNewCard = (values, { setSubmitting }) => {
    const { booking } = this.props.data;

    const { id3D = null } = values;

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    const data = { id3D };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous prenons votre réservation au golf...',
    });

    return Booking.api.bookingBook(apolloClient, booking)
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then((res) => {
        data.id = res.id;
        data.amount = res.total.price;
        this.setState({ status: 'Nous interrogeons votre banque...' });

        return this.capture(data, creditCard);
      })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ status: 'Erreur lors de la transaction, annulation en cours...' });

          return Booking.api.bookingCancel(apolloClient, { id: data.id, failed: true })
            .then(() => { throw Object.assign(err, { handled: true }); })
            .catch(() => { throw Object.assign(err, { handled: true }); });
        }
        throw err;
      })
      .then((res) => {
        data.transaction = {
          amount: data.amount,
          callNo: res.NUMAPPEL,
          transactionNo: res.NUMTRANS,
          card: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        };

        return Booking.api.bookingConfirm(apolloClient, {
          id: data.id,
          transaction: data.transaction,
        });
      })
      .catch((err) => {
        if (!err.handled) {
          return data;
        }
        this.setState({ error: err.message, waiting: false });
        throw err;
      })
      .then(({ transaction }) => {
        this.props.updateBooking({
          ...data,
          transaction,
        });
        this.props.history.replace('/booking/voucher');
      })
      .catch((err) => {
        if (!err.handled) {
          this.setState({ error: err.message, waiting: false });
        }
        setSubmitting(false);
      });
  };

  /* */
  render() {
    if (this.redirect) return <Redirect to="/booking" />;

    const {
      error,
      status,
      imprint,
      loading,
      waiting,
    } = this.state;
    const { amount } = this;
    const { data: { booking } } = this.props;

    const accsQty = booking.accessories.length;
    const numberOfPlayers = booking.players.length;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(booking.date)));
    const courseName = `${i18n.t(`terms.course_type_${booking.course.type}`)} "${booking.course.name}"`;
    const detailsTxt = `${i18n.t('terms.players', { count: numberOfPlayers })} • ${i18n.t('terms.rentals', { count: accsQty })}`;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Paiement par CB">
        <Typography
          align="center"
          component="h2"
          variant="h5">
          {`${dateTxt} • ${Booking.functions.formatTime(booking.time)}`}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
        <Typography
          align="center"
          component="p"
          variant="body1">
          {detailsTxt}
        </Typography>
        <br />
        <Payment
          amount={amount}
          imprint={imprint}
          currency={this.currency}
          session3DS={this.session3DS}
          payWithNewCard={this.payWithNewCard}
          payWithCardImprint={this.payWithCardImprint} />
        <DialogPaymentIP open={waiting} status={status} />
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, bookingData }) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
});

const StyledComponent = withStyles(styles)(BookingPayment);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
