import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createGenerateClassName, JssProvider } from 'react-jss';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
// Config must be first
import '_config';
import store from '_config/store';
// CSS
import 'intro.js/introjs.css';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

/* */
const generateClassName = createGenerateClassName();

/* */
ReactDOM.render(
  <Provider store={store}>
    <JssProvider generateClassName={generateClassName}>
      <App />
    </JssProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
