import React from 'react';
import moment from 'moment';
import * as qs from 'query-string';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import AccountIcon from '@material-ui/icons/AccountCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle';
import {
  App,
  Paybox,
  Account,
  helpers,
  Membership,
  CreditCard,
} from '@aps-management/primapp-common';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core';

/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import Payment from '_components/Payment';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = theme => ({
  listTitle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: `${theme.spacing.unit * 2}px`,
  },
  listContainer: {
    marginBottom: `${theme.spacing.unit * 5}px`,
  },
  totalLine: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  detailAmount: {
    minWidth: 80,
    textAlign: 'right',
  },
  remaining: { color: theme.palette.error.main },
  fullyPaid: { color: '#4caf50' },
  bold: { fontWeight: 'bold' },
});

/* */
class FullyPayMembership extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // ux
      error: null,
      screenLoading: true,
      buttonLoading: false,
      // data
      golf: null,
      account: null,
      membership: null,
      creditCard: null, // credit card existante
      isFullyCompleted: false, // membershipSchedule existant - paiement effectué
    };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { reference } = this.props.match.params;
    const { token } = qs.parse(this.props.location.search);

    if (token && reference) {
      Account.api.getAccountByToken(apolloClient, { token })
        .then((account) => {
          if (account) {
            const matching = account.matches.find(m => (m.token === token ? m : false));

            return App.api.getGolfInfo(apolloClient, { id: matching.golfId })
              .then(({ golf }) => {
                document.title = (golf && golf.name) || 'prima.golf';

                // set locale
                i18n.locale = golf.locale;
                this.currency = golf.currency;

                // build session 3DS
                this.session3DS = {
                  address: {
                    city: golf.city,
                    countryCode: 250, // golf.country
                    line1: golf.name,
                    zipCode: golf.postalCode,
                  },
                  customer: {
                    email: account.email,
                    lastName: account.lastname,
                    firstName: account.firstname,
                  },
                };

                return Promise.all([
                  Membership.api.getExercises(apolloClient, {
                    golfId: golf.id,
                    accountId: account.id,
                  }),
                  Membership.api.checkMembershipSchedule(masterClient, {
                    reference,
                    golfId: golf.id,
                    accountId: account.id,
                  }),
                ])
                  .then((data) => {
                    if (!data[0].exercises) {
                      throw new Error('Cotisation non trouvée');
                    }

                    let membership = null;
                    const { exercises } = data[0].exercises;
                    exercises.forEach((e) => {
                      e.memberships.forEach((m) => {
                        // Paiement comptant uniquement
                        if (m.reference === reference && m.paymentSchedule.length === 0) {
                          membership = m;
                          // Force le calcul du montant restant
                          membership.total.due = m.total.amount - m.total.paid;

                          // Init transaction à effectuer
                          this.transaction = {
                            amount: m.total.amount - m.total.paid,
                            reference: `MEMBERSHIP-${m.reference}`,
                          };
                        }
                      });
                    });
                    if (!membership) {
                      throw new Error('Cotisation non trouvée');
                    }

                    let isFullyCompleted = false;
                    if (data[1]
                      && data[1].reference
                      && data[1].frequency === 'single') {
                      isFullyCompleted = true;
                    }

                    return CreditCard.api.getCreditCards(apolloClient, { accountId: account.id })
                      .then(({ creditCards }) => {
                        let myCard = null;
                        if (creditCards[0]) {
                          myCard = {
                            ...creditCards[0],
                            ...CreditCard.functions.getStatus(creditCards[0].dueDate),
                          };
                        }

                        this.setState({
                          golf,
                          account,
                          membership,
                          isFullyCompleted,
                          creditCard: myCard,
                        });
                      });
                  });
              });
          }
          throw new Error('Client non trouvé');
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ screenLoading: false }));
    }
  }

  /* */
  renderHeader() {
    const { golf, account } = this.state;
    const { amount: dueAmount } = this.transaction;
    // const title = membership.members[0].category;

    return (
      <div>
        <Typography variant="h5" gutterBottom>
          {golf.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {'Paiement par carte bancaire'}
        </Typography>
        <br />
        <Typography variant="body2">
          {'Bonjour '}<b>{`${account.firstname} ${account.lastname}`}</b>{','}
        </Typography>
        {dueAmount > 0 ? (
          <Typography variant="body2">
            {'Le réglement de votre cotisation de '}<b>{`${i18n.l('currency', dueAmount / 100)}`}</b>{' se fera comptant sur votre carte bancaire.'}
          </Typography>
        ) : (
          <Typography variant="body2">
            {'Le réglement de votre cotisation se fera comptant sur votre carte bancaire.'}
          </Typography>
        )}
        <Typography variant="body2">
          {'Pour ce faire, veuillez renseigner / valider vos coordonnées de cartes bancaires ci-dessous.'}
        </Typography>
        <br />
      </div>
    );
  }

  /* */
  renderInfosGrid() {
    const { classes } = this.props;
    const { membership } = this.state;

    return (
      <Grid item md={6}>
        <Typography variant="h6">
          {'Informations'}
        </Typography>
        <br />
        <Typography>
          {`Période du ${i18n.l('date.formats.default', moment(membership.period.from).toDate())} `}
          {`au ${i18n.l('date.formats.default', moment(membership.period.to).toDate())}`}
        </Typography>
        <Typography>
          {`Établi le ${i18n.l('date.formats.default', moment(membership.createdAt).toDate())}`}
        </Typography>
        <br />
        {membership.members.map((member, i) => this.renderMemberDetails(member, i))}
        <Paper className={classes.listContainer}>
          <List disablePadding subheader={
            <ListSubheader
              color="inherit"
              className={classes.totalLine}>
              {'Total'}
            </ListSubheader>
          }>
            <ListItem>
              <ListItemText
                primary="Montant"
                primaryTypographyProps={{ color: 'primary', variant: 'body2' }} />
              <Typography variant="body2">
                {i18n.l('currency', membership.total.amount / 100)}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Déjà réglé"
                primaryTypographyProps={{ color: 'primary', variant: 'body2' }} />
              <Typography variant="body2">
                {i18n.l('currency', membership.total.paid / 100)}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Restant dû"
                primaryTypographyProps={{ color: 'primary', variant: 'body2' }} />
              <Typography
                variant="body2"
                className={classNames({
                  [classes.remaining]: membership.total.due > 0,
                  [classes.fullyPaid]: membership.total.due <= 0,
                })}>
                {i18n.l('currency', membership.total.due / 100)}
              </Typography>
            </ListItem>
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderMemberDetails(member, i) {
    const { classes } = this.props;
    const { membership } = this.state;

    const showSubtotal = membership.members.length > 1 && member.lines.length > 1;
    const subtotal = member.lines.reduce((counter, line) => counter + line.amount, 0);

    return (
      <Box key={`member-${i}`}>
        <div className={classes.listTitle}>
          <AccountIcon className="mr-10" />
          <Typography variant="subtitle1">
            {`${member.firstName} ${member.lastName.toUpperCase()}`}
          </Typography>
        </div>
        <Paper className={classes.listContainer}>
          <List disablePadding>
            {member.lines.map((line, j) => (
              <ListItem key={j} divider>
                <ListItemText
                  primary={line.name}
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'caption' }} />
                <Typography variant="body2" className={classes.detailAmount}>
                  {i18n.l('currency', (line.amount) / 100)}
                </Typography>
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem>
                <ListItemText
                  primary="Sous-total"
                  classes={{ primary: classes.bold }}
                  primaryTypographyProps={{ color: 'primary', variant: 'body2' }} />
                <Typography
                  color="primary"
                  variant="body2"
                  className={classes.bold}>
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
    );
  }

  /* */
  renderPaymentGrid() {
    const { creditCard, isFullyCompleted } = this.state;

    if (isFullyCompleted) {
      return (
        <Grid item md={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center">
            <CheckCircleOutlineIcon fontSize="large" color="primary" />
            <br />
            <Typography gutterBottom color="primary">
              {'Votre paiement a bien été enregistré.'}
            </Typography>
            <Typography gutterBottom color="primary">
              {'Une confirmation vous a été envoyé par e-mail.'}
            </Typography>
          </Box>
        </Grid>
      );
    }

    return (
      <Grid item md={6}>
        <Typography variant="h6">
          {'Paiement'}
        </Typography>
        <br />
        <Payment
          display='column'
          imprint={creditCard}
          currency={this.currency}
          session3DS={this.session3DS}
          defaultImprintUse = "disabled"
          amount={this.transaction.amount}
          payWithNewCard={this.payWithNewCard}
          payWithCardImprint={this.payWithCardImprint} />
      </Grid>
    );
  }

  /* */
  setMembershipPayment(transactionPaybox) {
    const { golf, account, membership } = this.state;

    const title = `${membership.members[0].category}`;

    return Membership.api.setMembershipPayment(apolloClient, {
      title,
      golfId: golf.id,
      accountId: account.id,
      year: membership.year,
      transaction: transactionPaybox,
      reference: membership.reference,
    });
  }

  /* */
  payWithNewCard = (values, { setSubmitting }) => {
    const { membership } = this.state;
    const { amount, reference } = this.transaction;
    const { id3D = null } = values;


    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      buttonLoading: true,
      status: 'Nous interrogeons votre banque...',
    });

    return Paybox.api.capture(apolloClient, {
      id3D,
      amount,
      reference,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .then((res) => {
        this.setState({ status: 'Nous confirmons votre paiement au golf...' });

        return this.setMembershipPayment({
          amount,
          callNo: res.NUMAPPEL,
          transactionNo: res.NUMTRANS,
          card: CreditCard.functions.anonymizeNumber(creditCard.number),
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        });
      })
      .then(() => {
        membership.total.due -= amount;
        membership.total.paid += amount;
        this.setState({ isFullyCompleted: true });
      })
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        setSubmitting(false);
        this.setState({ buttonLoading: false });
      });
  }

  /* */
  payWithCardImprint = () => {
    const { creditCard, membership } = this.state;
    const { amount, reference } = this.transaction;

    const subscriber = {
      id: creditCard.id,
      token: creditCard.token,
      dueDate: creditCard.dueDate,
    };

    this.setState({
      error: null,
      buttonLoading: true,
      status: 'Nous interrogeons votre banque...',
    });

    Paybox.api.captureSubscriber(apolloClient, {
      amount,
      reference,
      subscriber,
    })
      .then((res) => {
        this.setState({ status: 'Nous confirmons votre paiement au golf...' });

        return this.setMembershipPayment({
          amount,
          callNo: res.NUMAPPEL,
          transactionNo: res.NUMTRANS,
          card: creditCard.number,
          creditCardId: creditCard.id,
          ...helpers.object.filterByKeys(creditCard, ['type', 'brand', 'country']),
        });
      })
      .then(() => {
        membership.total.due -= amount;
        membership.total.paid += amount;
        this.setState({ isFullyCompleted: true });
      })
      .catch(err => this.setState({
        status: null,
        error: err.message,
      }))
      .finally(() => {
        this.setState({ buttonLoading: false });
      });
  }

  /* */
  render() {
    const {
      // ux
      error,
      status,
      buttonLoading,
      screenLoading,
      // data
      account,
      membership,
    } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        loading={screenLoading}>
        {account && membership && (
          <React.Fragment>
            {this.renderHeader()}
            <Grid
              container
              spacing={40}
              justify="center">
              {this.renderInfosGrid()}
              {this.renderPaymentGrid()}
            </Grid>
            <DialogPaymentIP open={buttonLoading} status={status} />
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

export default withStyles(styles)(FullyPayMembership);
