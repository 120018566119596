import {
  App,
  Paybox,
  Account,
  helpers,
  HELP_URL,
  Primaccess,
  CreditCard,
} from '@aps-management/primapp-common';
import {
  Link,
  Grid,
  Paper,
  Dialog,
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  ExpansionPanel,
  withMobileDialog,
  CircularProgress,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import React from 'react';
import * as qs from 'query-string';
import classNames from 'classnames';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { unstable_Box as Box } from '@material-ui/core/Box';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import NotFound from '_screens/Public/NotFound';
import MenuLoggedOut from '_components/core/MenuLoggedOut';
import GetCreditCard from '_components/GetCreditCard';
import { Alert, CreditCardPreview } from '_components/elements';

/* */
const styles = theme => ({
  panel: {
    display: 'inherit',
  },
  alert: {
    marginTop: theme.spacing.unit * 2,
  },
  largeButton: {
    width: 'calc(100% / 3)',
    marginLeft: 'calc(100% / 3)',
    marginRight: 'calc(100% / 3)',
  },
  dialogButton: {
    width: 150,
  },
  dialog: {
    minWidth: 300,
  },
  dialogTitle: {
    borderBottom: '1px solid #D9D9D9',
  },
  dialogContent: {
    padding: 15,
  },
  dialogActions: {
    borderTop: '1px solid #D9D9D9',
    margin: 0,
    padding: 25,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  textContainer: {
    margin: '50px 25px',
  },
  bold: {
    fontWeight: 800,
  },
  product: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 2,
  },
  selectedProduct: {
    boxShadow: '0px 1px 5px 1px rgba(0, 109, 183, 1)',
  },
});

class AutomaticRefill extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.authorizationAmount = 1000;

    this.state = {
      error: null,
      success: null,
      loading: true,
      imprint: null,
      expanded: false,
      golf: null,
      token: null,
      products: [],
      account: null,
      matching: null,
      refillStatus: null,
      buttonLoading: false,
      errorComponent: null,
      optOutModalOpened: false,
      chooseProductModalOpened: false,
      selectCreditCardModalOpened: false,
    };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { token } = qs.parse(this.props.location.search);

    this.setState({
      loading: true,
      imprint: null,
      token,
    });

    if (token) {
      Account.api.getAccountByToken(apolloClient, { token })
        .then((account) => {
          if (account) {
            Primaccess.api.getRefillStatusByToken(apolloClient, { token })
              .then(({ refillStatus }) => {
                const matching = account?.matches.find(m => (m.token === token ? m : false));
                App.api.getGolfInfo(apolloClient, { id: matching.golfId })
                  .then(({ golf }) => {
                    // set locale
                    i18n.locale = golf.locale;
                    this.currency = golf.currency;

                    // build session 3DS
                    this.session3DS = {
                      address: {
                        city: golf.city,
                        countryCode: 250, // golf.country
                        line1: golf.name,
                        zipCode: golf.postalCode,
                      },
                      customer: {
                        email: account.email,
                        lastName: account.lastname,
                        firstName: account.firstname,
                      },
                    };

                    Primaccess.api.getProducts(apolloClient, {
                      golfId: golf.id,
                      accountId: account.id,
                    })
                      .then((products) => {
                        products.sort(({ price: a }, { price: b }) => a - b);
                        this.setState({
                          golf,
                          account,
                          products,
                          matching,
                          refillStatus,
                          loading: false,
                          product: (
                            refillStatus && JSON.parse(refillStatus.product)
                          ) || products[0],
                        });
                      });
                  }).catch(() => this.setState({ error: new Error('Golf introuvable'), errorComponent: <NotFound /> }));
              });
            CreditCard.api.getCreditCards(apolloClient, { token, accountId: account.id })
              .then(({ creditCards }) => {
                const newState = {
                  imprint: null,
                  expanded: true,
                };

                if (creditCards[0]) {
                  const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
                  newState.imprint = {
                    ...status,
                    ...creditCards[0],
                  };
                  newState.expanded = status.isExpired;
                }

                this.setState(newState);
              });
          } else {
            this.setState({ error: new Error('Compte introuvable'), errorComponent: <NotFound /> });
          }
        })
        .catch(error => this.setState({ error, errorComponent: <NotFound /> }));
    }
  }

  /* */
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { id3D = null } = values;
    const { account } = this.state;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({ error: null, success: null });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(() => {
        const status = CreditCard.functions.getStatus(creditCard.dueDate);
        this.setState({
          expanded: false,
          imprint: { ...status, ...creditCard },
          success: 'Votre carte est enregistrée avec succès.',
        });
        resetForm();
      })
      .catch(err => this.setState({ error: err.message }))
      .finally(() => setSubmitting(false));
  }

  /* */
  enableRefill = () => {
    const { product, account, golf } = this.state;

    this.setState({
      buttonLoading: true,
    });

    Primaccess.api.setRefillStatus(apolloClient, {
      status: true,
      golfId: golf.id,
      accountId: account.id,
      product: JSON.stringify(product),
    })
      .then((setRefillStatus) => {
        if (setRefillStatus) {
          this.setState({
            buttonLoading: false,
            chooseProductModalOpened: false,
            selectCreditCardModalOpened: false,
            refillStatus: setRefillStatus,
          });
        }
      });
  }

  /* */
  disableRefill = () => {
    const { account, golf } = this.state;

    this.setState({
      buttonLoading: true,
    });

    Primaccess.api.setRefillStatus(apolloClient, {
      accountId: account.id,
      golfId: golf.id,
      status: false,
    })
      .then((setRefillStatus) => {
        if (setRefillStatus) {
          this.setState({
            buttonLoading: false,
            optOutModalOpened: false,
            refillStatus: setRefillStatus,
          });
        }
      });
  }

  /* */
  toggleModal = (name) => {
    this.setState({ [`${name}ModalOpened`]: !this.state[`${name}ModalOpened`] });
  }

  /* */
  handleExpand = (event, expanded) => this.setState({ expanded });

  /* */
  renderForm(alreadyExisting) {
    const { classes } = this.props;
    const { expanded } = this.state;

    const form = (
      <GetCreditCard
        currency={this.currency}
        session3DS={this.session3DS}
        onSubmit={this.handleSubmit}
        authorizationAmount={this.authorizationAmount} />
    );

    return (
      <ExpansionPanel
        expanded={expanded}
        onChange={this.handleExpand}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}>
          <Typography
            gutterBottom
            variant="subtitle1"
            component='h2'>
            {`${alreadyExisting ? 'Changer de' : 'Ajouter une'} carte bancaire`}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.panel}>
          {form}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  /* */
  renderStatus() {
    const { classes } = this.props;
    const {
      golf,
      product,
      refillStatus,
    } = this.state;

    return (
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          className={classes.bold}
          variant="h5"
          component="h2">
          {'Rechargement automatique'}
        </Typography>
        {(!refillStatus || (refillStatus && !refillStatus.status))
          && (
            <React.Fragment>
              <div className={classes.textContainer}>
                <Typography
                  align="justify"
                  paragraph
                  variant="body1">
                  {`La proposition de rechargement en ligne s’étoffe au ${golf && golf.name},
                  en plus de la formule traditionnelle `}<Link
                    color="secondary"
                    underline="hover"
                    variant="subtitle1"
                    href={`/${golf && golf.slug}/primaccess`}
                    target="_blank"
                    rel="noopener">
                    {'disponible ici'}
                  </Link>.
                </Typography>
                <Typography
                  paragraph
                  variant="body1">
                  {'Choisissez 2, 5, 10, 20 ou 30 seaux. Lorsque le solde de votre carte atteint 2 seaux, le rechargement s\'effectuera automatiquement.'}
                </Typography>
                <Typography
                  variant="body1">
                  {'Rendez-vous plus serein à votre golf !'}
                </Typography>
              </div>
              <Button
                size="large"
                color="secondary"
                variant="contained"
                onClick={() => this.toggleModal('chooseProduct')}
                className={classes.largeButton}>
                {'Activer'}
              </Button>
              {this.renderChooseProductModal()}
              {this.renderSelectCreditCardModal()}
            </React.Fragment>
          )
        }
        {refillStatus && refillStatus.status
          && (
            <React.Fragment>
              <div className={classes.textContainer}>
                <Typography
                  align="justify"
                  paragraph
                  variant="body1">
                  {'Le rechargement automatique est actuellement '}<b>{`${refillStatus.status ? 'activé' : 'désactivé'}`}</b>{'.'}
                </Typography>
                {product && (
                  <Typography variant="body2">
                    {'Produit sélectionné : '}<b>{`${product.name} (${i18n.l('currency', product.price / 100)})`}</b>{'.'}
                  </Typography>
                )}
              </div>
              <Button
                size="large"
                variant="contained"
                onClick={() => this.toggleModal('optOut')}
                className={classes.largeButton}>
                {'Désactiver'}
              </Button>
              {this.renderOptOutModal()}
            </React.Fragment>
          )
        }
      </Grid>
    );
  }

  /* */
  selectProduct = product => () => this.setState({ product });

  /* */
  renderProduct = (p, k) => {
    const { classes } = this.props;
    const { product } = this.state;

    const className = classNames(classes.product, {
      [classes.selectedProduct]: (product === p),
    });

    return (
      <Paper
        key={k}
        className={className}
        onClick={this.selectProduct(p)}>
        <Typography>
          {p.name.toUpperCase()}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary">
          {i18n.l('currency', p.price / 100)}
        </Typography>
      </Paper>
    );
  }

  /* */
  renderProductPicker() {
    const { loading, products } = this.state;

    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center">
          <CircularProgress size={24} color="secondary" />
        </Box>
      );
    }

    if (products && products.length === 0) {
      return (
        <Typography align="center">
          {'Aucun produit disponible.'}
        </Typography>
      );
    }

    return (
      <Box
        mt={4}
        mb={4}>
        {products.map(this.renderProduct)}
      </Box>
    );
  }

  /* */
  renderImprint() {
    const { imprint } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {imprint
          && (
            <CreditCardPreview
              brand={imprint.brand}
              dueDate={imprint.dueDate}
              number={imprint.number} />
          )
        }
        {imprint && imprint.isExpired
          && (
            <Alert
              variant="error"
              className={classes.alert}>
              {'La carte est expirée.'}
            </Alert>
          )
        }
        {imprint && imprint.isSoonExpired
          && (
            <Alert
              variant="warning"
              className={classes.alert}>
              {'La carte arrive bientôt à expiration.'}
            </Alert>
          )
        }
      </React.Fragment>
    );
  }

  /* */
  renderChooseProductModal() {
    const { classes, fullScreen } = this.props;
    const { chooseProductModalOpened, product } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={chooseProductModalOpened}
        classes={{ paperFullWidth: classes.dialog }}
        onClose={() => this.toggleModal('chooseProduct')}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography
            variant="h6"
            component='span'>
            {'Sélectionnez un produit'}
          </Typography>
          <IconButton
            color="primary"
            size="medium"
            onClick={() => this.toggleModal('chooseProduct')}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {this.renderProductPicker()}
          <Typography
            variant="body1"
            component='span'>
            {'Dès que votre solde atteindra '}<b>{'2'}</b>{' seaux, vous serez recrédité automatiquement de '}
            <b>{`${product && product.value}`}</b>{' seaux.'}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => this.toggleModal('selectCreditCard')}
            className={classes.dialogButton}>
            {'SUIVANT'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  renderSelectCreditCardModal() {
    const { classes, fullScreen } = this.props;
    const { selectCreditCardModalOpened, buttonLoading, imprint } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={selectCreditCardModalOpened}
        classes={{ paperFullWidth: classes.dialog }}
        onClose={() => this.toggleModal('selectCreditCard')}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography
            variant="h6"
            component='span'>
            {'Moyen de paiement'}
          </Typography>
          <IconButton
            color="primary"
            size="medium"
            onClick={() => this.toggleModal('selectCreditCard')}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container justify="center" spacing={16}>
            {imprint && (
              <Grid item xs={11} sm={8} md={6}>
                <Typography
                  variant="body1"
                  component='span'>
                  {'Carte bancaire actuelle :'}
                </Typography>
                {this.renderImprint()}
                <Typography
                  variant="body2"
                  component='span'>
                  {'Le rechargement automatique utilisera cette carte bancaire.'}
                </Typography>
              </Grid>
            )}
            <Grid item xs={11} sm={8} md={6}>
              {imprint && this.renderForm(true)}
              {!imprint && this.renderForm()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            size="large"
            onClick={() => this.toggleModal('selectCreditCard')}
            className={classes.dialogButton}>
            {'RETOUR'}
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={this.enableRefill}
            disabled={buttonLoading || !imprint || (imprint && imprint.isExpired)}
            className={classes.dialogButton}>
            {buttonLoading ? (
              <CircularProgress
                size={28}
                thickness={4}
                color="secondary" />
            ) : 'ACTIVER'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  renderOptOutModal() {
    const { classes, fullScreen } = this.props;
    const { buttonLoading, optOutModalOpened } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={optOutModalOpened}
        classes={{ paperFullWidth: classes.dialog }}
        onClose={() => this.toggleModal('optOut')}>
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            color="primary"
            size="medium"
            onClick={() => this.toggleModal('optOut')}
            className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component='span'>
            {'Êtes-vous sûr ?'}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography
            variant="body1"
            component='span'>
            {'En désactivant le rechargement automatique, vous ne serez plus crédité automatiquement lorsque votre solde est faible.'}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={this.disableRefill}
            disabled={buttonLoading}
            className={classes.dialogButton}>
            {buttonLoading ? (
              <CircularProgress
                size={28}
                thickness={4}
                color="secondary" />
            ) : 'DÉSACTIVER'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  render() {
    const {
      golf,
      error,
      loading,
      success,
      account,
      errorComponent,
    } = this.state;

    if (errorComponent) return errorComponent;

    return (
      <Screen
        hideWeather
        hideHomeIcon
        error={error}
        success={success}
        loading={loading}
        noDrawer={loading}
        title={golf && golf.name}
        helpURL={`${HELP_URL}/carte-bancaire`}
        drawerComponent={<MenuLoggedOut account={account} golf={golf} />}
        onBackPress={() => this.props.history.goBack()}>
        <Grid
          container
          spacing={40}
          justify="center">
          {this.renderStatus()}
        </Grid>
      </Screen>
    );
  }
}

const StyledComponent = withStyles(styles)(AutomaticRefill);
const WithMobileDialogComponent = withMobileDialog({ breakpoint: 'xs' })(StyledComponent);

export default connect(CreditCard.actions)(WithMobileDialogComponent);
