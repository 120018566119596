import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { helpers, Directory } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  AppBar,
  Dialog,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import apolloClient from '_utils/apolloClient';
import { AddContactForm } from '_components/forms';
import { Alert, Wrapper } from '_components/elements';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const styles = theme => ({
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    position: 'relative',
  },
  rightButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  flex: {
    flex: 1,
  },
  paper: {
    padding: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
});

/* */
class AddContact extends React.Component {
  /* */
  state = {
    error: null,
  };

  /* */
  constructor(props) {
    super(props);

    const schema = {
      lastname: validators.required,
      firstname: validators.required,
    };
    if (props.required) {
      if (props.required.licenseNumber) {
        schema.licenseNumber = validators.required;
      }
    }

    this.validationSchema = createValidationSchema(schema);
  }

  /* */
  handleClose = () => {
    this.props.onClose();
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    Directory.api.setContact(apolloClient, { ...values })
      .then(({ contact }) => {
        this.props.addContact(contact);
        setSubmitting(false);
        this.handleClose();
      })
      .catch((e) => {
        this.setState({ error: helpers.string.handleError(e.message) });
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { error } = this.state;
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.handleClose}
        classes={{ paperFullScreen: classes.dialog }}>
        <AppBar color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Fermer"
              onClick={this.handleClose}
              className={classes.rightButton}>
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              className={classes.flex}>
              {'Ajouter un contact'}
            </Typography>
          </Toolbar>
        </AppBar>
        <Wrapper layout="fixed">
          <Paper className={classes.paper}>
            <Alert variant="error">{error}</Alert>
            <Formik
              initialValues={{
                email: '',
                phone: '',
                lastname: '',
                firstname: '',
                licenseNumber: '',
              }}
              onSubmit={this.onSubmit}
              component={AddContactForm}
              validationSchema={this.validationSchema} />
          </Paper>
        </Wrapper>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ app, bookingData, bookingSearch }) =>
  ({ app, data: bookingData, search: bookingSearch });

const StyledComponent = withStyles(styles)(AddContact);

export default connect(
  mapStateToProps,
  Directory.actions,
)(StyledComponent);
