import React from 'react';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Link } from 'react-router-dom';

/* */
const styles = theme => ({
  toolbar: {
    height: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      backgroundColor: theme.palette.common.white,
      ...theme.mixins.toolbar,
    },
  },
  email: { color: theme.palette.grey[500] },
  identity: { padding: theme.spacing.unit * 1 },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing.unit * 1,
  },
  login: {
    display: 'block',
    margin: `${theme.spacing.unit * 1}px auto`,
  },
});

/* */
class MenuLoggedOut extends React.PureComponent {
  /* */
  handleExpand = type => () => { this.props.togglePanelNav(type); };

  /* */
  isExpanded = type => this.props.navDrawer.includes(type);

  /* */
  render() {
    const { accountReference } = this;
    const {
      classes,
      account,
      golf,
    } = this.props;

    const firstname = account && account.firstname;
    const lastname = account && account.lastname;

    return (
      <div>
        <div className={classes.toolbar} />
        <div className={classes.identity} >
          <Avatar className={classes.avatar} title={accountReference}>
            {`${firstname && firstname[0]}${lastname && lastname[0]}`.toUpperCase()}
          </Avatar>
          <Typography
            component="p"
            variant="h6">
            {`${firstname} ${lastname}`}
          </Typography>
          <Typography
            className={classes.email}
            component="p"
            variant="body2">
            {account && account.email}
          </Typography>
        </div>
        <Divider />
        <Button
          fullWidth
          color="secondary"
          component={Link}
          className={classes.login}
          to={`/${golf && golf.slug}/login`}>
          {i18n.t('auth.form.actions.log_in')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { navDrawer, golf } }) => ({
  navDrawer,
  wording: golf && golf.options && golf.options.wording,
});

const StyledComponent = withStyles(styles)(MenuLoggedOut);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
